import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '../../app/AppStyles';
import { fetchFavorites } from '../favorites/favoritesSlice';
import { fetchUserProgress } from '../progress/progressSlice';
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import PageLayout from '../subcomponents/PageLayout';
import RegisterPerformancesCard from './RegisterPerformancesCard';
import { fetchPeriods, fetchRegister, setPerformance, setPerformances } from './registerSlice';

export default function RegisterPerformancesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, name } = useParams();

    const { register, registerStatus, performances, performancesStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { student } = useSelector((state) => state.student);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { userProgressStatus } = useSelector((state) => state.progress);

    const [inRooster, setInRooster] = useState(false);

    useEffect(() => {
        if (student !== null && userProgressStatus !== "success" && registerStatus !== "success") {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchUserProgress({ departmentId: activeDepartment[0].id, fullProgress: false }))
                };
            } else {
                dispatch(fetchUserProgress({ departmentId: null, fullProgress: false }))
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, student, activeDepartment, departmentThemesStatus, siomSettings]);

    useEffect(() => {
        if (student !== null && userProgressStatus === "success" && registerStatus !== "success") {
            dispatch(setPerformance(null));

            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    navigate("/inschrijven");
                };
            } else {
                dispatch(fetchRegister(null));
                dispatch(fetchFavorites(null));
                dispatch(fetchPeriods(null));
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, student, userProgressStatus, departmentThemesStatus, activeDepartment, siomSettings]);

    useEffect(() => {
        if (registerStatus === "success" && performances === null) {
            dispatch(setPerformances(register?.find(item => item.activityId === id)?.activityPerformances));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, registerStatus, id]);

    const handleChange = () => {
        setInRooster(!inRooster);
    };

    const FilterFragment = () => {
        return (
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={inRooster} onChange={handleChange} />} label={`${resourceFinal[appLanguage].messages.ALWAYS_AVAILABLE_CHECKBOX}`} />
            </FormGroup>
        );
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.PERFORMANCES}`} content={
            <ContentLoader successCondition={performancesStatus === "success"} errorCondition={performancesStatus === "failed"} content={
                <>
                    <Grid container spacing={1} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12}><Typography sx={{ ...PageHeader, typography: { xs: 'h5', md: 'h3' } }}>{decodeURIComponent(name)}</Typography></Grid>
                        {inRooster ?
                            <>
                                {performances?.filter((item) => item.inRooster === true).length > 0 ?
                                    performances?.filter((item) => item.inRooster === true).map((item) =>
                                        <Grid item xs={12} md={6} key={item.performanceId}>
                                            <RegisterPerformancesCard performance={item} />
                                        </Grid>
                                    ) : <Grid item xs><Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography></Grid>}
                            </>
                            :
                            <>
                                {performances?.length > 0 ?
                                    performances?.map((item) =>
                                        <Grid item xs={12} md={6} key={item.performanceId}>
                                            <RegisterPerformancesCard performance={item} />
                                        </Grid>
                                    ) : <Grid item xs><Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography></Grid>}
                            </>
                        }
                    </Grid>
                </>
            } />
        } contentLeft={
            <FilterSidebar filters={<FilterFragment />} />
        } bottomBar contentBottom={
            <FilterButton filters={<FilterFragment />} />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } />
    );
}
