import { Box, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLastUsedDepartment } from '../settings/themeSettingsSlice';
import Flag from './Flag';

const flag = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};

export default function FlagDisplay({ progressFlags, xsIconSize, mdIconSize, compact, sizeOverride, departmentSettings, departmentId, departmentOverride }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { activeThemeSettings, activeDepartment } = useSelector((state) => state.settings.theme);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { lifestyleStatus, flagsGreen, flagsYellow, flagsOrange } = progressFlags;
    const desktop = useMediaQuery('(min-width:1536px)');

    const flagClick = () => {
        if (departmentSettings) {
            dispatch(updateLastUsedDepartment(departmentId));

            if (activeDepartment[0].id !== departmentId) {
                navigate('/Voortgang');
                navigate(0);
                return;
            };
        };

        navigate('/Voortgang');
    };

    const tjlClick = () => {
        window.open("https://www.testjeleefstijl.nl/", '_blank');
    };

    return (
        <>
            {compact ?
                <Grid container spacing={0.5} justifyContent="center">
                    <Grid item xs={sizeOverride ? 3 : 6} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={flagClick} sx={{ p: 0 }}>
                            <Flag
                                count={flagsGreen}
                                type='green'
                                style={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: green[500] }}
                                badgePosition={{ vertical: 'top', horizontal: 'right' }}
                                settings={departmentOverride ? departmentSettings : activeThemeSettings}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item xs={sizeOverride ? 3 : 6} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={flagClick} sx={{ p: 0 }}>
                            <Flag
                                count={flagsYellow}
                                type='yellow'
                                style={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: yellow[600] }}
                                badgePosition={{ vertical: 'top', horizontal: 'right' }}
                                settings={departmentOverride ? departmentSettings : activeThemeSettings}
                            />
                        </IconButton>
                    </Grid>
                    {departmentOverride ?
                        <>
                            {departmentSettings.showOrangeFlags ?
                                <Grid item xs={sizeOverride ? 3 : 6} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <IconButton onClick={flagClick} sx={{ p: 0 }}>
                                        <Flag
                                            count={flagsOrange}
                                            type='red'
                                            style={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: red[500] }}
                                            badgePosition={{ vertical: 'top', horizontal: 'right' }}
                                            settings={departmentOverride ? departmentSettings : activeThemeSettings}
                                        />
                                    </IconButton>
                                </Grid>
                                : ""}
                            {departmentSettings.tjlEnabled === true ?
                                <Grid item xs={sizeOverride ? 3 : 6} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <IconButton onClick={tjlClick} sx={{ p: 0 }}>
                                        <Flag
                                            count={lifestyleStatus}
                                            tjl={true}
                                            style={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: lifestyleStatus === 2 ? green[500] : lifestyleStatus === 1 ? yellow[600] : red[500] }}
                                            settings={departmentOverride ? departmentSettings : activeThemeSettings}
                                        />
                                    </IconButton>
                                </Grid>
                                : ""}
                        </>
                        :
                        <>
                            {activeThemeSettings.showOrangeFlags ?
                                <Grid item xs={sizeOverride ? 3 : 6} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <IconButton onClick={flagClick} sx={{ p: 0 }}>
                                        <Flag
                                            count={flagsOrange}
                                            type='red'
                                            style={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: red[500] }}
                                            badgePosition={{ vertical: 'top', horizontal: 'right' }}
                                            settings={departmentOverride ? departmentSettings : activeThemeSettings}
                                        />
                                    </IconButton>
                                </Grid>
                                : ""}
                            {activeThemeSettings.tjlEnabled === true ?
                                <Grid item xs={sizeOverride ? 3 : 6} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <IconButton onClick={tjlClick} sx={{ p: 0 }}>
                                        <Flag
                                            count={lifestyleStatus}
                                            tjl={true}
                                            style={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: lifestyleStatus === 2 ? green[500] : lifestyleStatus === 1 ? yellow[600] : red[500] }}
                                            settings={departmentOverride ? departmentSettings : activeThemeSettings}
                                        />
                                    </IconButton>
                                </Grid>
                                : ""}
                        </>}
                </Grid>
                :
                <Stack spacing={0.5} >
                    <Stack direction="row" spacing={1}>
                        <Flag
                            count={flagsGreen}
                            type='green'
                            style={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: green[500] }}
                            badgePosition={{ vertical: 'top', horizontal: 'right' }}
                            settings={departmentOverride ? departmentSettings : activeThemeSettings}
                        />
                        <Box sx={{ ...flag }}>
                            <Typography variant="body1">{resourceFinal[appLanguage].messages.YOU_HAVE_FLAGS} {flagsGreen} {flagsGreen !== 1 ? resourceFinal[appLanguage].words.FLAGS_GREEN_MULT.toLowerCase() : resourceFinal[appLanguage].words.FLAGS_GREEN.toLowerCase()}.</Typography>
                        </Box>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Flag
                            count={flagsYellow}
                            type='yellow'
                            style={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: yellow[600] }}
                            badgePosition={{ vertical: 'top', horizontal: 'right' }}
                            settings={departmentOverride ? departmentSettings : activeThemeSettings}
                        />
                        <Box sx={{ ...flag }}>
                            <Typography variant="body1">{resourceFinal[appLanguage].messages.YOU_HAVE_FLAGS} {flagsYellow} {flagsYellow !== 1 ? resourceFinal[appLanguage].words.FLAGS_YELLOW_MULT.toLowerCase() : resourceFinal[appLanguage].words.FLAGS_YELLOW.toLowerCase()}.</Typography>
                        </Box>
                    </Stack>
                    {activeThemeSettings.showOrangeFlags ?
                        <Stack direction="row" spacing={1}>
                            <Flag
                                count={flagsOrange}
                                type='red'
                                style={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: red[500] }}
                                badgePosition={{ vertical: 'top', horizontal: 'right' }}
                                settings={departmentOverride ? departmentSettings : activeThemeSettings}
                            />
                            <Box sx={{ ...flag }}>
                                <Typography variant="body1">{resourceFinal[appLanguage].messages.YOU_HAVE_FLAGS} {flagsOrange} {flagsOrange !== 1 ? resourceFinal[appLanguage].words.FLAGS_ORANGE_MULT.toLowerCase() : resourceFinal[appLanguage].words.FLAGS_ORANGE.toLowerCase()}.</Typography>
                            </Box>
                        </Stack>
                        : ""}
                    {activeThemeSettings.tjlEnabled ?
                        <Stack direction="row" spacing={1}>
                            <IconButton onClick={tjlClick}>
                                <Flag
                                    count={lifestyleStatus}
                                    tjl={true}
                                    style={{ fontSize: { xs: xsIconSize, md: mdIconSize }, color: lifestyleStatus === 2 ? green[500] : lifestyleStatus === 1 ? yellow[600] : red[500] }}
                                    settings={departmentOverride ? departmentSettings : activeThemeSettings}
                                />
                            </IconButton>
                            <Box sx={{ ...flag }}>
                                <Typography variant="body1">
                                    {lifestyleStatus === 2 ? resourceFinal[appLanguage].messages.TJL_COMPLETED :
                                        lifestyleStatus === 1 ? resourceFinal[appLanguage].messages.TJL_STARTED :
                                            resourceFinal[appLanguage].messages.TJL_NOT_STARTED}
                                </Typography>
                            </Box>
                        </Stack>
                        : ""}
                </Stack>
            }
        </>
    );
}
