import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterSidebar from '../subcomponents/FilterSidebar';
import NoContentDisplay from '../subcomponents/NoContentDisplay';
import PageLayout from '../subcomponents/PageLayout';
import TravelDeclarationEmployeeCard from './TravelDeclarationEmployeeCard';
import { fetchDeclarationsForEmployee, resetJudgementStatus } from './travelCostSlice';

export default function TravelReimbursePage() {
    const dispatch = useDispatch();
    const amount = 4;

    const [filter, setFilter] = useState("");
    const [approved, setApproved] = useState(false);
    const [itemsLeft, setItemsLeft] = useState(0);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { studentDeclarations, studentDeclarationsStatus } = useSelector((state) => state.travelCost);
    const { declarationJudgementStatus } = useSelector((state) => state.travelCost);

    useEffect(() => {
        dispatch(fetchDeclarationsForEmployee(approved ? true : null));
    }, [dispatch, approved]);

    // Put status callback
    useEffect(() => {
        if (declarationJudgementStatus === "success") {
            dispatch(resetJudgementStatus());
            dispatch(fetchDeclarationsForEmployee(approved ? true : null));
        };
    }, [dispatch, declarationJudgementStatus]);

    useEffect(() => {
        if (studentDeclarationsStatus === "success") {
            const size = studentDeclarations?.length;
            setItemsLeft(size - amount);
        }
    }, [studentDeclarationsStatus, amount, studentDeclarations]);

    useEffect(() => {
        setPages(Math.ceil(itemsLeft / amount) + 1);
    }, [itemsLeft, amount]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const FilterFragment = () => {
        return (
            <>
                {studentDeclarationsStatus === "success" &&
                    <FormGroup sx={{ width: 1 }}>
                        <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={approved} onChange={() => setApproved(!approved)} />} label={`${resourceFinal[appLanguage].words.APPROVED_STATUS}`} />
                    </FormGroup>
                }
            </>
        );
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.student.fullname.toLowerCase().includes(filter.toLowerCase()) ||
            item.student.studentNumber.toString().includes(filter.toLowerCase()) ||
            item.performance.performanceName.toLowerCase().includes(filter.toLowerCase()) ||
            item.performance.performanceNumber.toString().includes(filter.toLowerCase()) ||
            item.performance.activityName.toLowerCase().includes(filter.toLowerCase());
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.TRAVEL_COSTS} ${resourceFinal[appLanguage].words.REIMBURSE.toLowerCase()}`} content={
            <ContentLoader successCondition={studentDeclarationsStatus === "success"} errorCondition={studentDeclarationsStatus === "failed"} content={
                <>
                    <Grid container spacing={1}>
                        {studentDeclarations?.filter(customFilterFunction)?.slice((page - 1) * amount, page * amount)?.map((item) =>
                            <Grid item key={item.id} xs={12} md={6}>
                                <TravelDeclarationEmployeeCard declaration={item} />
                            </Grid>
                        )}
                        {studentDeclarations?.filter(customFilterFunction)?.length <= 0 &&
                            <Grid item xs={12}>
                                <NoContentDisplay message={resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY} />
                            </Grid>
                        }
                    </Grid>
                    {(itemsLeft > 0 && !isNaN(pages)) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto', p: 1 }}>
                            <Pagination count={pages} page={page} onChange={handlePageChange} defaultPage={1} color="secondary" />
                        </Box>
                    }
                </>
            } />
        } contentLeft={<FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />} bottomBar searchFunc={handleSearchChange} />
    );
}
