import { Button, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putStudentInfo } from './studentSlice';

export default function StudentInfoEditCard({ property }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { putStudentInfoStatus } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    const [saveFailed, setSaveFailed] = useState(false);
    const [disabled, setDisabled] = useState(false);

    function isValid(input) {
        if (input === "" || input === null || property === "drivingLicence" || property === "pokTurnedIn" || property === "ovSubscription" || property === "allergy")
            return true;

        if (property === "privateEmail")
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input);

        if (property === "privatePhone")
            return /^\(?(0)-?6(\s?|-)([0-9]\s{0,2}){8}$/.test(input);

        if (property === "workPhone")
            return /^((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9]){8}$/.test(input);

        if (property === "ibanNumber")
            return /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/.test(input);
    };

    useEffect(() => {
        if (user !== null) {
            if (property === "privateEmail")
                setValue(user?.privateEmail);

            if (property === "privatePhone")
                setValue(user?.privatePhone);

            if (property === "workPhone")
                setValue(user?.extraInfo.emergencyPhone);

            if (property === "ibanNumber")
                setValue(user?.extraInfo.ibanNumber);

            if (property === "allergy")
                setValue(user?.extraInfo.allergy);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (error !== null && isValid(value)) {
            setError(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = event => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (putStudentInfoStatus === "success") {
            window.location.reload();
        } else if (putStudentInfoStatus === "failed") {
            setSaveFailed(true);
            setDisabled(false);
        };
    }, [dispatch, putStudentInfoStatus]);

    const submitValue = () => {
        if (!isValid(value) || value === null) {
            if (property === "privateEmail")
                setError(resourceFinal[appLanguage].messages.INVALID_EMAIL);

            if (property === "privatePhone")
                setError(resourceFinal[appLanguage].messages.INVALID_MOBILE_PHONE);

            if (property === "workPhone")
                setError(resourceFinal[appLanguage].messages.INVALID_PHONE);

            if (property === "ibanNumber")
                setError(resourceFinal[appLanguage].messages.INVALID_IBAN);
        } else {
            if (property === "privateEmail")
                dispatch(putStudentInfo({ key: 'Email', secondaryKey: 'Private', newValue: value }));

            if (property === "privatePhone")
                dispatch(putStudentInfo({ key: 'Phone', secondaryKey: 'Private', newValue: value }));

            if (property === "workPhone")
                dispatch(putStudentInfo({ key: 'Phone', secondaryKey: 'Work', newValue: value }));

            if (property === "drivingLicence")
                dispatch(putStudentInfo({ key: 'DrivingLicence', newValue: value }));

            if (property === "ibanNumber")
                dispatch(putStudentInfo({ key: 'IbanNumber', newValue: value }));

            if (property === "pokTurnedIn")
                dispatch(putStudentInfo({ key: 'PokTurnedIn', newValue: value }));

            if (property === "ovSubscription") {
                if (value === "Week") {
                    dispatch(putStudentInfo({ key: 'OvWeek', newValue: "True" }));
                    dispatch(putStudentInfo({ key: 'OvWeekend', newValue: "False" }));
                } else if (value === "Weekend") {
                    dispatch(putStudentInfo({ key: 'OvWeek', newValue: "False" }));
                    dispatch(putStudentInfo({ key: 'OvWeekend', newValue: "True" }));
                } else {
                    dispatch(putStudentInfo({ key: 'OvWeek', newValue: "False" }));
                    dispatch(putStudentInfo({ key: 'OvWeekend', newValue: "False" }));
                }
            };

            if (property === "allergy")
                dispatch(putStudentInfo({ key: 'Allergy', newValue: value }));

            setDisabled(true);
        };
    };

    return (
        <Card sx={{ display: 'flex', p: 3 }}>
            <Stack spacing={1} sx={{ flex: "1 1 auto", alignItems: 'center' }}>
                {property === "privateEmail" || property === "privatePhone" || property === "workPhone" || property === "ibanNumber" || property === "allergy" ?
                    <TextField
                        fullWidth
                        defaultValue={
                            property === "privatePhone" ? user.privatePhone :
                                property === "privateEmail" ? user.privateEmail :
                                    property === "workPhone" ? user.extraInfo.emergencyPhone :
                                        property === "ibanNumber" ? user.extraInfo.ibanNumber :
                                            property === "allergy" ? user.extraInfo.allergy :
                                                ""}
                        error={error !== null}
                        helperText={error}
                        variant="outlined"
                        onChange={handleChange}
                        label={
                            property === "privatePhone" ? resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER :
                                property === "privateEmail" ? resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE :
                                    property === "workPhone" ? resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE :
                                        property === "ibanNumber" ? resourceFinal[appLanguage].words.USER_DATA_IBAN :
                                            property === "allergy" ? resourceFinal[appLanguage].words.USER_DATA_ALLERGY :
                                                ""}
                    /> : ""}

                {property === "ovSubscription" ?
                    <FormControl>
                        <FormLabel>{resourceFinal[appLanguage].words.USER_DATA_OV}</FormLabel>
                        <RadioGroup onChange={handleChange}>
                            <FormControlLabel value="Week" control={<Radio />} label={resourceFinal[appLanguage].words.STUDENT_DATA_WEEK} />
                            <FormControlLabel value="Weekend" control={<Radio />} label={resourceFinal[appLanguage].words.STUDENT_DATA_WEEKEND} />
                            <FormControlLabel value="Geen" control={<Radio />} label={resourceFinal[appLanguage].words.NO} />
                        </RadioGroup>
                    </FormControl>
                    : ""}

                {property === "drivingLicence" || property === "pokTurnedIn" ?
                    <FormControl>
                        <FormLabel>
                            {property === "drivingLicence" ? resourceFinal[appLanguage].words.USER_DATA_LICENCE :
                                property === "pokTurnedIn" ? resourceFinal[appLanguage].words.USER_DATA_POK :
                                    ""}
                        </FormLabel>
                        <RadioGroup onChange={handleChange}>
                            <FormControlLabel value="True" control={<Radio />} label={resourceFinal[appLanguage].words.YES} />
                            <FormControlLabel value="False" control={<Radio />} label={resourceFinal[appLanguage].words.NO} />
                        </RadioGroup>
                    </FormControl>
                    : ""}

                {saveFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                <Button disabled={disabled} variant="outlined" color="secondary" onClick={submitValue} sx={{ ml: 1 }}>{resourceFinal[appLanguage].words.SAVE}</Button>
            </Stack>
        </Card>
    );
}
