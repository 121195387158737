import { Box, Button, ButtonGroup, Card, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { getSubprograms, postSubprogram, putSubprogram, setFailedSubprogram } from './subprogramManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function SubprogramEditCard({ handleClose, addNew }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedSubprogram, postSubprogramStatus, putSubprogramStatus, showSubprogramFailed } = useSelector((state) => state.management.subprogram);

    // Put status callback
    useEffect(() => {
        if (putSubprogramStatus === "success") {
            dispatch(setFailedSubprogram(false));
            dispatch(getSubprograms());
            handleClose();
        } else if (putSubprogramStatus === "failed") {
            dispatch(setFailedSubprogram(true));
        };
    }, [dispatch, handleClose, putSubprogramStatus]);

    // Post status callback
    useEffect(() => {
        if (postSubprogramStatus === "success") {
            setFailedSubprogram(false);
            dispatch(getSubprograms());
            handleClose();
        } else if (postSubprogramStatus === "failed") {
            setFailedSubprogram(true);
        };
    }, [dispatch, handleClose, postSubprogramStatus]);

    // Character limits
    const nameLength = 256;

    // Property states
    const [newSubprogram, setNewSubprogram] = useState({});
    const [errorName, setErrorName] = useState(null);
    const [changed, setChanged] = useState(false);

    // Selected program state update 
    useEffect(() => {
        if (!addNew && selectedSubprogram)
            setNewSubprogram(selectedSubprogram);
    }, [selectedSubprogram, addNew]);

    // Keep track of changed state 
    useEffect(() => {
        if (newSubprogram !== selectedSubprogram) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedSubprogram, newSubprogram]);

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newSubprogram.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newSubprogram.name && newSubprogram.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        newSubprogram.CreatedBy = `AP/${user.number}`;

        dispatch(postSubprogram(newSubprogram));
    };

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        if (!newSubprogram.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newSubprogram.name && newSubprogram.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        newSubprogram.ModifiedBy = `AP/${user.number}`;

        dispatch(putSubprogram(newSubprogram));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.PROGRAM} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth required
                                defaultValue={selectedSubprogram?.name}
                                label={resourceFinal[appLanguage].words.NAME}
                                onChange={(event) => setNewSubprogram({
                                    ...newSubprogram,
                                    name: event.target.value
                                })}
                                error={errorName !== null}
                                helperText={errorName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth multiline
                                defaultValue={selectedSubprogram?.description}
                                label={resourceFinal[appLanguage].words.DESCRIPTION}
                                onChange={(event) => setNewSubprogram({
                                    ...newSubprogram,
                                    description: event.target.value
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {showSubprogramFailed && <>
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_DUPLICATE_NAME}</Typography>
                            </>}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                                {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}
