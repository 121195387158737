import { Grid } from '@mui/material';
import NewsTile from "../news/NewsTile";
import InfoTile from "./InfoTile";

export default function EmployeeHome() {

    return (
        <Grid container direction="column" wrap="nowrap" spacing={{ xs: 0.5, md: 1 }} sx={{ flex: '1 1 auto' }}>
            <Grid item xs={12}><InfoTile /></Grid>
            <Grid item xs sx={{ display: 'flex', flex: '1 1 auto' }}><NewsTile /></Grid>
        </Grid>
    );
}