import EventIcon from '@mui/icons-material/Event';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardHeader, MainIconStyle, SubHeader } from '../../app/AppStyles';
import { GetDateLong, GetTime } from "../../app/AppUtils";
import DelayedPaymentButton from '../mollie/DelayedPaymentButton';
import IconDisplay from '../subcomponents/IconDisplay';
import LocationButton from '../subcomponents/LocationButton';
import { deleteSubscriptionStudent, resetRegisterStatusses } from '../register/registerSlice';
import ConfirmationModal from '../subcomponents/ConfirmationModal';
import { fetchSchedule } from './scheduleSlice';
import { useEffect } from 'react';
import BadgeIcon from '@mui/icons-material/Badge';

export default function ScheduleDetails({ scrollText }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.user);
    const { momentDetails } = useSelector((state) => state.schedule);
    const { deleteStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { departmentThemes } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appSettings } = useSelector((state) => state.settings.app);

    const accentColor = siomSettings.useMultipleDepartments ? departmentThemes?.filter(x => x.id === momentDetails?.departmentId)[0]?.themeSettings.primaryColor : 'secondary.main';

    const activityClick = (name, id) => {
        navigate(`/activiteit/${encodeURIComponent(name)}/${id}`);
    };

    const linkClick = (path) => {
        window.open(`${path}`, '_blank');
    };

    const locationClick = (location) => {
        window.open(`https://www.google.nl/maps/dir//+${location}`, '_blank');
    };

    const deleteClick = () => {
        dispatch(deleteSubscriptionStudent({
            studentId: user.studentId,
            subscriptionId: momentDetails?.subscriptionId,
            performanceId: momentDetails?.performanceId,
            createdBy: `AP/${user.number}`
        }));
    };

    useEffect(() => {
        if (deleteStatus === "success") {
            dispatch(resetRegisterStatusses());
            dispatch(fetchSchedule());
            navigate('/agenda');
        }
    }, [deleteStatus]);

    const ItemFragment = ({ icon, text, label, style, click, size }) => {
        return (
            <Grid item xs={12} md={size}>
                <ListItem sx={{ p: 0 }}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        sx={{ ...style }}
                        onClick={click}
                        primary={text}
                        secondary={label}
                    />
                </ListItem>
            </Grid>
        );
    };

    const TextFragment = () => {
        return (
            <>
                {momentDetails?.activityDescription ?
                    <Box sx={{ m: 1 }}>
                        <Typography variant="h6" gutterBottom sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.ACTIVITY} {resourceFinal[appLanguage].words.DESCRIPTION.toLowerCase()}</Typography>
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(momentDetails?.activityDescription.replace(/\\n/g, '<br />')) }}></Typography>
                    </Box> : ""}
                {momentDetails?.performanceDescription ?
                    <Box sx={{ m: 1 }}>
                        <Typography variant="h6" gutterBottom sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE} {resourceFinal[appLanguage].words.REMARK.toLowerCase()}</Typography>
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(momentDetails?.performanceDescription.replace(/\\n/g, '<br />')) }}></Typography>
                    </Box> : ""}
                {momentDetails?.momentDescription ?
                    <Box sx={{ m: 1 }}>
                        <Typography variant="h6" gutterBottom sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.REMARK.toLowerCase()}</Typography>
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(momentDetails?.momentDescription.replace(/\\n/g, '<br />')) }}></Typography>
                    </Box> : ""}
            </>
        );
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 1 }}>
            <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: accentColor, ml: 0 }}>
                    <IconDisplay component={momentDetails?.activityIcon} style={{ color: 'white.main' }} />
                </Avatar>
                <Typography variant="h5" sx={{ ...CardHeader, color: accentColor }}>{momentDetails?.activity}</Typography>
            </Stack>
            <Grid container>
                {momentDetails?.unsubscribable && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                    <ConfirmationModal buttonText={resourceFinal[appLanguage].words.UNSUBSCRIBE} buttonColor={'error'} buttonStyle={{ width: '50%' }} disabledCondition={!momentDetails?.unsubscribable} message={`${resourceFinal[appLanguage].messages.UNSUBSCRIBE_CONFIRMATION} ${momentDetails?.activity}?`} confirmAction={() => deleteClick()} />
                </Grid>}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, p: 1 }}>
                    {momentDetails?.isReservation && <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>{resourceFinal[appLanguage].words.RESERVATION}</Typography>}
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, p: 1 }}>
                    <DelayedPaymentButton isReservation={momentDetails?.isReservation} performanceId={momentDetails?.performanceId} subscriptionId={momentDetails?.subscriptionId} />
                </Grid>
                <ItemFragment size={12} icon={<EventIcon sx={{ ...MainIconStyle }} />}
                    label={
                        appSettings?.showSchedulePeriod ?
                            `${resourceFinal[appLanguage].words.DATE}, ${resourceFinal[appLanguage].words.TIME} & ${resourceFinal[appLanguage].words.PERIOD}` :
                            `${resourceFinal[appLanguage].words.DATE} & ${resourceFinal[appLanguage].words.TIME}`
                    }
                    text={
                        appSettings?.showSchedulePeriod ?
                            `${GetDateLong(momentDetails?.startDate)} | ${GetTime(momentDetails?.startDate)} - ${GetTime(momentDetails?.endDate)} | ${momentDetails?.period.name}` :
                            `${GetDateLong(momentDetails?.startDate)} | ${GetTime(momentDetails?.startDate)} - ${GetTime(momentDetails?.endDate)}`
                    } />
                {momentDetails?.contactperson ? <ItemFragment size={12} icon={<PersonIcon sx={{ ...MainIconStyle }} />}
                    text={momentDetails?.contactperson} label={resourceFinal[appLanguage].words.CONTACTPERSON} /> : ""}
                <ItemFragment size={12} icon={
                    <IconButton onClick={() => activityClick(momentDetails?.activity, momentDetails?.activityId)}>
                        <IconDisplay component={momentDetails?.activityIcon} style={{ color: 'secondary.main' }} />
                    </IconButton>} style={{ cursor: 'pointer' }} click={() => activityClick(momentDetails?.activity, momentDetails?.activityId)}
                    text={momentDetails?.activity} label={resourceFinal[appLanguage].words.ACTIVITY} />
                {momentDetails?.performanceName ? <ItemFragment size={12} icon={<BadgeIcon sx={{ ...MainIconStyle }} />}
                    text={momentDetails?.performanceName} label={`${resourceFinal[appLanguage].words.PERFORMANCE} ${resourceFinal[appLanguage].words.NAME.toLowerCase()}`} /> : ""}

                <ItemFragment size={12} icon={<LocationButton locationString={`${momentDetails?.location.street}+${momentDetails?.location.streetNumber}+${momentDetails?.location.city}`} />}
                    text={<>{momentDetails?.location.name} {momentDetails?.location.street ?
                        "(" + momentDetails?.location.street + (momentDetails?.location.streetNumber ? " " + momentDetails?.location.streetNumber : "") +
                        (momentDetails?.location.city ? ", " + momentDetails?.location.city + ")" : ")") :
                        momentDetails?.location.city ? `(${momentDetails?.location.city})` : ""}</>}
                    label={resourceFinal[appLanguage].words.LOCATION} style={{ cursor: 'pointer' }} click={() => locationClick(`${momentDetails?.location.street}+${momentDetails?.location.streetNumber}+${momentDetails?.location.city}`)} />
                {momentDetails?.linkUrl ? <ItemFragment size={12} icon={
                    <IconButton onClick={() => linkClick(momentDetails?.linkUrl)}>
                        <LinkIcon color="secondary" />
                    </IconButton>}
                    text={momentDetails?.linkName ? momentDetails?.linkName : resourceFinal[appLanguage].words.LINK} label={resourceFinal[appLanguage].words.LINK}
                    style={{ cursor: 'pointer' }} click={() => linkClick(momentDetails?.linkUrl)} /> : ""}
            </Grid>

            {scrollText ?
                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <TextFragment />
                </Scrollbars>
                :
                <TextFragment />}
        </Box>
    );
}