import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Slices
import { fetchFavoriteSettings } from '../../components/favorites/favoritesSlice';
import { setActiveDepartment, updateLastUsedDepartment } from '../../components/settings/themeSettingsSlice';
import { fetchStudentSettings } from '../../components/student/studentSlice';

// Components
import { Backdrop, Button, Card, CardContent, Typography } from '@mui/material';
import { ApplicationPaths } from '../../components/api-authorization/ApiAuthorizationConstants';
import FavoritesWarning from '../../components/favorites/FavoritesWarning';
import Notifications from '../../components/notifications/Notifications';
import NotificationsWarning from '../../components/notifications/NotificationsWarning';
import ScheduleReservationWarning from '../../components/schedule/ScheduleReservationWarning';
import StudentInfoWarning from '../../components/student/StudentInfoWarning';
import UserWarning from '../../components/student/UserWarning';

export default function StudentChecks() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { activeDepartment, departmentThemes, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { studentInfoSettings, studentInfoSettingsStatus } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { permission } = useSelector((state) => state.notifications);
    const { userCheck, userCheckStatus, user, userStatus } = useSelector((state) => state.user);
    const { favoriteSettings, favoriteSettingsStatus } = useSelector((state) => state.favorites);

    const [supportsPush, setSupportsPush] = useState(false);
    const [notificationsCheck, setNotificationsCheck] = useState(false);
    const [userInfoCheck, setUserInfoCheck] = useState(false);
    const [favoritesCheck, setFavoritesCheck] = useState(false);

    // Check if push notifications are supported
    useEffect(() => {
        navigator.serviceWorker.getRegistration()
            .then(function (registration) {
                if (registration.pushManager) {
                    setSupportsPush(true);
                };
            });
    }, []);

    // Fetch student settings
    useEffect(() => {
        if (user !== null) {
            dispatch(fetchStudentSettings());
        };
    }, [dispatch, user]);

    // Fetch favorite settings
    useEffect(() => {
        if (user !== null) {
            if (siomSettings?.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchFavoriteSettings(activeDepartment[0].id));
                };
            } else {
                dispatch(fetchFavoriteSettings(null));
            };
        };
    }, [dispatch, siomSettings, user, activeDepartment, departmentThemesStatus]);

    // Set notifications check
    useEffect(() => {
        setNotificationsCheck(
            (appSettings?.notificationRequired && permission === "granted") || !appSettings?.notificationRequired
        );
    }, [appSettings, permission]);

    // Set user info check
    useEffect(() => {
        if (studentInfoSettings !== null) {
            setUserInfoCheck(
                (!studentInfoSettings?.privateEmailRequired || studentInfoSettings?.privateEmail) &&
                (!studentInfoSettings?.privatePhoneRequired || studentInfoSettings?.privatePhone) &&
                (!studentInfoSettings?.workPhoneRequired || studentInfoSettings?.workPhone) &&
                (!studentInfoSettings?.ibanRequired || studentInfoSettings?.iban) &&
                (!studentInfoSettings?.pokRequired || studentInfoSettings?.pok !== "")
            );
        };
    }, [studentInfoSettings]);

    // Set favorites check
    useEffect(() => {
        if (favoriteSettingsStatus === 'success') {
            setFavoritesCheck(
                (!favoriteSettings?.forceFavorite || favoriteSettings?.userFavorites >= favoriteSettings?.favoriteMinimum)
            );
        };
    }, [favoriteSettingsStatus, favoriteSettings]);

    // Push user to department picker if last used department is empty
    useEffect(() => {
        if (userCheck === 4 && userInfoCheck &&
            ((appSettings?.notificationRequired && permission === "granted") || !appSettings?.notificationRequired) &&
            siomSettings?.useMultipleDepartments && departmentThemesStatus === "success" && activeDepartment === null
        ) {
            if (departmentThemes.length > 1) {
                navigate("/afdeling-kiezer");
            } else {
                dispatch(setActiveDepartment(departmentThemes))
                dispatch(updateLastUsedDepartment(departmentThemes[0].id));
                navigate(0);
            };
        };
    }, [userInfoCheck, departmentThemesStatus]);

    return (
        <>
            {(appSettings?.notificationEnabled && supportsPush) && <Notifications />}
            {userCheckStatus === 'success' && userStatus === 'success' && studentInfoSettingsStatus === 'success' ?
                <>
                    <UserWarning />
                    {(userCheck === 4 && appSettings?.notificationRequired && supportsPush) && <NotificationsWarning />}
                    {(userCheck === 4 && notificationsCheck && !userInfoCheck) && <StudentInfoWarning />}
                    {(userCheck === 4 && notificationsCheck && userInfoCheck && !favoritesCheck) && <FavoritesWarning />}
                    {(userCheck === 4 && notificationsCheck && userInfoCheck && favoritesCheck) && <ScheduleReservationWarning />}
                </>
                :
                <>
                    {userStatus === 'failed' &&
                        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                            <Card sx={{ m: 1, px: 2, py: 1, textAlign: 'center' }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{ fontSize: '1.4em' }}>{resourceFinal[appLanguage].messages.ERROR_MESSAGE_GET_STUDENT_FAILED}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: '1.2em' }}>{resourceFinal[appLanguage].messages.AUTOMATIC_REFERRAL}</Typography>
                                    <Button variant="outlined" color="secondary" sx={{ mt: 2 }} onClick={() => navigate(`${ApplicationPaths.Login}`)}>{resourceFinal[appLanguage].words.LOGIN}</Button>
                                </CardContent>
                            </Card>
                        </Backdrop>}
                </>
            }
        </>
    );
}
