import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, Box, Checkbox, FormControlLabel, FormGroup, IconButton, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { DisplayName } from '../../../app/AppUtils';
import { fetchAllRegisterForEmployee, fetchPeriodsForEmployee, fetchRegisterForEmployee, setAllPeriodChecks, setCheckedPeriod } from '../../register/registerSlice';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import ErrorDisplay from '../../subcomponents/ErrorDisplay';
import FilterSidebar from '../../subcomponents/FilterSidebar';
import PageLayout from '../../subcomponents/PageLayout';
import { fetchSearchStudent, setStudentRegisterFilter, setStudentRegisterPage, setStudentRegisterParentCheck, setStudentRegisterReasonMode } from '../searchSlice';
import SearchStudentInfoTab from './SearchStudentInfoTab';
import SearchStudentPerformanceTab from './SearchStudentPerformanceTab';
import SearchStudentProgressTab from './SearchStudentProgressTab';
import SearchStudentRegisterTab from './SearchStudentRegisterTab';
import SearchStudentSummaryTab from './SearchStudentSummaryTab';

export default function SearchStudentResultPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchStudent, searchStudentStatus, studentRegisterPage, studentRegisterReasonMode, studentRegisterParentCheck } = useSelector((state) => state.search);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { deleteSubscriptionStatus } = useSelector((state) => state.subscription);
    const { registerStatus, registerAllStatus, periods, periodsStatus, subscriptionStatus } = useSelector((state) => state.register);

    const [value, setValue] = useState(0);

    const BackClick = () => {
        if (studentRegisterPage === 'REGISTER')
            navigate(-1);
        else
            dispatch(setStudentRegisterPage("REGISTER"));
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            dispatch(setStudentRegisterFilter(value));
        }, 600);
    };

    useEffect(() => {
        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading") {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchSearchStudent({ studentId: id, departmentId: activeDepartment[0].id }));
                };
            } else {
                dispatch(fetchSearchStudent({ studentId: id, departmentId: null }));
            };
        };
    }, [dispatch, id, activeDepartment, siomSettings.useMultipleDepartments, deleteSubscriptionStatus, subscriptionStatus]);

    useEffect(() => {
        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading" && siomSettings?.showSearchRegisterTab) {
            dispatch(setStudentRegisterPage("REGISTER"));

            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchPeriodsForEmployee({ studentId: id, departmentId: activeDepartment[0].id }));

                    if (studentRegisterReasonMode)
                        dispatch(fetchAllRegisterForEmployee({ studentId: id, departmentId: activeDepartment[0].id }))
                    else
                        dispatch(fetchRegisterForEmployee({ studentId: id, departmentId: activeDepartment[0].id }));
                };
            } else {
                dispatch(fetchPeriodsForEmployee({ studentId: id, departmentId: null }));

                if (studentRegisterReasonMode)
                    dispatch(fetchAllRegisterForEmployee({ studentId: id, departmentId: null }))
                else
                    dispatch(fetchRegisterForEmployee({ studentId: id, departmentId: null }));
            };
        };
    }, [dispatch, id, siomSettings, activeDepartment, studentRegisterReasonMode, deleteSubscriptionStatus, subscriptionStatus]);

    const FilterFragment = () => {
        return (
            <>
                {periodsStatus === "success" && (registerStatus === "success" || registerAllStatus === "success") ?
                    <Box>
                        <ToggleButtonGroup
                            color="primary"
                            fullWidth
                            exclusive
                            value={studentRegisterReasonMode ? "EMPLOYEE" : "STUDENT"}
                            onChange={(value) => dispatch(setStudentRegisterReasonMode(value === "EMPLOYEE" ? true : false))}
                        >
                            {siomSettings?.showSearchRegisterTab && <ToggleButton value="STUDENT">{resourceFinal[appLanguage].words.STUDENT}</ToggleButton>}
                            <ToggleButton value="EMPLOYEE">{resourceFinal[appLanguage].words.ROLE_EMPLOYEE}</ToggleButton>
                        </ToggleButtonGroup>
                        <FormControlLabel
                            label={resourceFinal[appLanguage].words.PERIODS}
                            control={
                                <Checkbox
                                    checked={studentRegisterParentCheck}
                                    onChange={
                                        () => {
                                            dispatch(setStudentRegisterParentCheck())
                                            periods.forEach((item) => {
                                                dispatch(setAllPeriodChecks({ id: item.periodId, bool: !studentRegisterParentCheck }));
                                            });
                                        }
                                    }
                                />
                            } />
                        <FormGroup sx={{ width: 1, alignSelf: 'center' }}>
                            {periods?.map((item) => <PeriodCheckbox key={item.periodId} item={item} />)}
                        </FormGroup>
                    </Box> : ""}
            </>
        );
    };

    const PeriodCheckbox = ({ item }) => {
        return (
            <FormControlLabel sx={{ alignSelf: 'left', ml: 2 }} control={<Checkbox checked={item.checked} onChange={() => {
                dispatch(setCheckedPeriod(item.periodId))
            }} />} label={item.name} />
        );
    };

    return (
        <PageLayout title={searchStudentStatus === "success" ? `${DisplayName(searchStudent.studentData?.firstName, searchStudent.studentData?.middleName, searchStudent.studentData?.lastName)} (${searchStudent.studentData?.number} - ${searchStudent.studentData?.extraInfo?.class ?? ""})` : `${resourceFinal[appLanguage].words.STUDENT}`} content={
            <ContentLoader successCondition={searchStudentStatus === "success"} errorCondition={searchStudentStatus === "failed"} content={
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label={resourceFinal[appLanguage].words.PROGRESS} />
                            {siomSettings?.showSearchRegisterTab && <Tab label={resourceFinal[appLanguage].words.REGISTER} />}
                            <Tab label={resourceFinal[appLanguage].words.STUDENT_DATA} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <SearchStudentProgressTab />
                    </TabPanel>
                    {siomSettings?.showSearchRegisterTab && <TabPanel value={value} index={1}>
                        {studentRegisterPage === 'REGISTER' ? <SearchStudentRegisterTab /> :
                            studentRegisterPage === 'PERFORMANCE' ? <SearchStudentPerformanceTab studentId={id} /> :
                                studentRegisterPage === 'SUMMARY' ? <SearchStudentSummaryTab studentId={id} /> :
                                    <ErrorDisplay />}
                    </TabPanel>}
                    <TabPanel value={value} index={siomSettings?.showSearchRegisterTab ? 2 : 1}>
                        <SearchStudentInfoTab />
                    </TabPanel>
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                {value !== 1 && <BackButton desktop />}
                {value === 1 &&
                    <Tooltip title={resourceFinal[appLanguage].words.BACK} placement="left" arrow>
                        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={BackClick}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <ArrowBackIcon sx={{ color: 'white.main', fontSize: 48 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>}
            </Box>
        } contentLeft={
            (value === 1 && siomSettings?.showSearchRegisterTab) && <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar />
    );

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
            >
                {value === index && (
                    <Box sx={{ display: 'flex', flex: '1 1 auto', p: 2, borderRadius: '0px 0px 8px 8px' }}>
                        {children}
                    </Box>
                )}
            </Box>
        );
    };
}
