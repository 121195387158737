import { Avatar, Box, Card, CardMedia, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useSelector } from "react-redux";
import { CardHeader, CardLarge, MainBorderRadius, SubHeader } from '../../app/AppStyles';
import IconDisplay from '../subcomponents/IconDisplay';
import LocationButton from '../subcomponents/LocationButton';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BarChartIcon from '@mui/icons-material/BarChart';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupsIcon from '@mui/icons-material/Groups';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';


export default function ActivityCard({ activity }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);

    const linkClick = (path) => window.open(`${path}`, '_blank');
    const locationClick = (location) => window.open(`https://www.google.nl/maps/dir//+${location}`, '_blank');

    return (
        <Card sx={{ ...CardLarge }}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <CardMedia
                        component="img" sx={{ borderRadius: { xs: '0px', md: '6px 0px' } }}
                        image={activity.imageUrl ? activity.imageUrl : '/imgs/Placeholder.png'} alt="activity"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ pt: 1 }} >
                        <Grid container>
                            <Grid item xs={12} sx={{ ml: 2 }}>
                                <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{ bgcolor: 'secondary.main' }}>
                                        <IconDisplay component={activity.iconName} style={{ color: 'white.main' }} />
                                    </Avatar>
                                    <Typography variant="h5" sx={{ ...CardHeader }}>{activity.name}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={<IconDisplay component={resourceFinal[appLanguage].words.TYPES} style={{ color: 'primary.main', p: 1 }} />}
                                    primaryText={activity.activityTypes[0].headType}
                                    secondaryText={resourceFinal[appLanguage].words.TYPE_HEAD}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={<IconDisplay component={activity.activityTypes[0].headType} style={{ color: 'primary.main', p: 1 }} />}
                                    primaryText={activity.activityTypes.map((item, index) => (index ? ', ' : '') + item.type)}
                                    secondaryText={activity.activityTypes.length > 1 ? resourceFinal[appLanguage].words.TYPES : resourceFinal[appLanguage].words.TYPE}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={appSettings?.appVersion === 1 ? <BarChartIcon sx={{ p: 1 }} color="primary" /> : <AccessTimeIcon sx={{ p: 1 }} color="primary" />}
                                    primaryText={activity.points === 0 ? "-" : activity.points}
                                    secondaryText={`${resourceFinal[appLanguage].words.DEFAULT} ${appSettings?.appVersion === 1 ?
                                        resourceFinal[appLanguage].words.POINTS.toLowerCase() : resourceFinal[appLanguage].words.HOURS.toLowerCase()}`}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={<GroupsIcon sx={{ p: 1 }} color="primary" />}
                                    primaryText={activity.maxStudentSubscriptions === 0 ? "-" : activity.maxStudentSubscriptions}
                                    secondaryText={resourceFinal[appLanguage].words.OCCUPATION_MAX}
                                />
                            </Grid>
                            {activity.organiser && <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={<CorporateFareIcon sx={{ p: 1 }} color="primary" />}
                                    primaryText={activity.organiser}
                                    secondaryText={resourceFinal[appLanguage].words.ORGANISER}
                                />
                            </Grid>}
                            {activity.contactPerson && <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={<PersonIcon sx={{ p: 1 }} color="primary" />}
                                    primaryText={activity.contactPerson}
                                    secondaryText={resourceFinal[appLanguage].words.CONTACTPERSON}
                                />
                            </Grid>}
                            {activity.defaulLocationNaam && <Grid item xs={12} md={activity.linkUrl ? 6 : 12}>
                                <ListFragment
                                    icon={<LocationButton locationString={`${activity.defaulLocationStreet}+${activity.defaulLocationCity}`} />}
                                    primaryText={<>{activity.defaulLocationNaam}</>}
                                    secondaryText={`${activity.defaulLocationStreet ? activity.defaulLocationStreet + ", " : ""}${activity.defaulLocationCity}`}
                                    textStyle={{ cursor: 'pointer' }}
                                    textClick={() => locationClick(`${activity.defaulLocationStreet}+${activity.defaulLocationCity}`)}
                                />
                            </Grid>}
                            {activity.linkUrl && <Grid item xs={12} md={6}>
                                <ListFragment
                                    icon={
                                        <IconButton onClick={() => linkClick(activity.linkUrl)}>
                                            <LinkIcon color="secondary" />
                                        </IconButton>
                                    }
                                    primaryText={activity.linkName ? activity.linkName : resourceFinal[appLanguage].words.LINK}
                                    secondaryText={resourceFinal[appLanguage].words.LINK}
                                    textStyle={{ cursor: 'pointer' }}
                                    textClick={() => linkClick(activity.linkUrl)}
                                />
                            </Grid>}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Stack spacing={0.5} sx={{ display: 'flex', flex: '1 1 auto', p: 1 }}>
                {activity.cost > 0 &&
                    <Typography variant="body1" sx={{ bgcolor: 'error.light', color: 'white.main', fontWeight: 'bold', textAlign: 'center', borderRadius: MainBorderRadius, p: 0.5 }}>
                        {resourceFinal[appLanguage].messages.PAID_CLINIC} {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(activity?.cost)}!
                    </Typography>
                }
                <Box>
                    <Typography variant="h6" sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</Typography>
                    {activity.description ?
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity.description.replace(/\\n/g, '<br />')) }}></Typography> :
                        <Typography variant="body1">-</Typography>
                    }
                </Box>
                {activity.embeddedVideo &&
                    <Box sx={{ display: 'flex', mt: 1, width: { xs: '100%', md: '60%' }, flex: '1 1 auto', boxSizing: 'border-box', alignSelf: 'center' }}>
                        <iframe
                            title="Video player"
                            width="100%"
                            frameBorder="0"
                            src={activity.embeddedVideo}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Box>
                }
            </Stack>
        </Card>
    );
}

const ListFragment = ({ icon, primaryText, secondaryText, textStyle, textClick }) => {
    return (
        <ListItem>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText
                sx={{ ...textStyle }} onClick={textClick}
                primary={primaryText}
                secondary={secondaryText}
            />
        </ListItem>
    );
};
