import { Badge, Button, Stack, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HomeButton } from '../../app/AppStyles';
import IconDisplay from '../subcomponents/IconDisplay';

export default function TileButton({ text }) {
    const navigate = useNavigate();
    const { newsCount } = useSelector((state) => state.news);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleRedirect = (path) => {
        let page = "/";

        if (path === `/${resourceFinal[appLanguage].words.SCHEDULE}`) page = '/agenda';
        if (path === `/${resourceFinal[appLanguage].words.REGISTER}`) page = '/inschrijven';
        if (path === `/${resourceFinal[appLanguage].words.PROGRESS}`) page = '/voortgang';
        if (path === `/${resourceFinal[appLanguage].words.CONTACT}`) page = '/contact';
        if (path === `/${resourceFinal[appLanguage].words.NEWS}`) page = '/nieuws';
        if (path === `/${resourceFinal[appLanguage].words.FAVORITES}`) page = '/favorieten';
        if (path === `/${resourceFinal[appLanguage].words.INFORMATION}`) page = '/informatie';
        if (path === `/${resourceFinal[appLanguage].words.TIMETABLE}`) page = '/rooster';
        if (path === `/${resourceFinal[appLanguage].words.ACTIVITY_MANAGEMENT}`) page = '/activiteitenbeheer';
        if (path === `/${resourceFinal[appLanguage].words.PROGRAM_MANAGEMENT}`) page = '/programmabeheer';
        if (path === `/${resourceFinal[appLanguage].words.PLANNING}`) page = '/plannen';
        if (path === `/${resourceFinal[appLanguage].words.WEB_MANAGEMENT}`) page = '/webbeheer';
        if (path === `/${resourceFinal[appLanguage].words.REPORTS_PAGE}`) page = '/rapporten';
        if (path === `/${resourceFinal[appLanguage].words.ADMIN_PAGE}`) page = '/admin';
        if (path === `/${resourceFinal[appLanguage].words.HOME}`) page = '/';
        if (path === `/${resourceFinal[appLanguage].words.ABOUT}`) page = '/over';
        if (path === `/${resourceFinal[appLanguage].words.ATTENDANCE}`) page = '/presentie';
        if (path === `/${resourceFinal[appLanguage].words.SEARCH_PAGE}`) page = '/zoeken/student';
        if (path === `/${resourceFinal[appLanguage].words.MESSAGES_PAGE}`) page = '/berichten';
        if (path === `/${resourceFinal[appLanguage].words.EMPLOYEE_PAGE}`) page = '/medewerkers';
        if (path === `/${resourceFinal[appLanguage].words.TRAVEL_COSTS}`) page = '/reiskosten';

        navigate(page);
    };

    return (
        <>
            <Button sx={{ ...HomeButton }} variant="contained" onClick={() => handleRedirect(`/${text}`)}>
                <Stack alignItems="center">
                    {text === 'Nieuws' && newsCount > 0 ? <Badge badgeContent={newsCount} color="secondary"><IconDisplay component={text} style={{ color: 'white.main' }} /></Badge> : <IconDisplay style={{ color: 'white.main' }} component={text} />}
                    <Typography variant="body1" noWrap sx={{ fontSize: { xs: 15, md: 17 }, color: 'white.main' }}>{text}</Typography>
                </Stack>
            </Button>
        </>
    )
}