import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";
import { useDispatch, useSelector } from 'react-redux';
import { HandleImageUpload } from '../../app/AppUtils';
import { fetchDefaultThemeSettings } from '../settings/themeSettingsSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import useSearchDebounce from '../subcomponents/SearchDebounce';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { putImageSetting, putSetting, resetUpdateStatus } from './adminSlice';

export default function ThemeSettingsTab() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { defaultTheme, defaultThemeStatus } = useSelector((state) => state.settings.theme);
    const { updateSettingStatus, updateImageSettingStatus } = useSelector((state) => state.admin);

    // Put Callback
    useEffect(() => {
        if (updateSettingStatus === "success" || updateImageSettingStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchDefaultThemeSettings());
        } else if (updateSettingStatus === "failed" || updateImageSettingStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateSettingStatus, updateImageSettingStatus]);

    // Primary color setting
    const [newPrimaryColor, setNewPrimaryColor] = useSearchDebounce();
    const [primaryColorChanged, setPrimaryColorChanged] = useState(false);
    useEffect(() => {
        if (newPrimaryColor && primaryColorChanged) {
            if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(newPrimaryColor)) {
                dispatch(putSetting({
                    key: "AppTheme_Primary_Color",
                    value: newPrimaryColor,
                    modifiedBy: `AP/${user?.number}`
                }));
            };
        };
    }, [dispatch, user, newPrimaryColor, primaryColorChanged]);

    // Secondary color setting
    const [newSecondaryColor, setNewSecondaryColor] = useSearchDebounce();
    const [secondaryColorChanged, setSecondaryColorChanged] = useState(false);
    useEffect(() => {
        if (newSecondaryColor && secondaryColorChanged) {
            if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(newSecondaryColor)) {
                dispatch(putSetting({
                    key: "AppTheme_Secondary_Color",
                    value: newSecondaryColor,
                    modifiedBy: `AP/${user?.number}`
                }));
            };
        };
    }, [dispatch, user, newSecondaryColor, secondaryColorChanged]);

    // Banner logo settings
    const [newBannerLogo, setNewBannerLogo] = useState(null);
    const [newBannerLogoName, setNewBannerLogoName] = useState(null);
    const [errorImage, setErrorImage] = useState(null);

    function handleImageChange(event) {
        setErrorImage(null);
        var imagePromise = HandleImageUpload(event);

        imagePromise.then(
            (value) => {
                setNewBannerLogoName(value?.name);
                setNewBannerLogo(value?.image);
            },
            (error) => {
                (error.message === "SIZE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_TOO_LARGE));
                (error.message === "TYPE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_NOT_IMAGE));
            });
    };

    useEffect(() => {
        if (newBannerLogo) {
            dispatch(putImageSetting({
                key: "AppTheme_Logo",
                imageUrl: newBannerLogo,
                imageName: newBannerLogoName,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newBannerLogo]);

    // Flags enabled setting
    const [newFlagsEnabled, setNewFlagsEnabled] = useState(null);
    useEffect(() => {
        if (newFlagsEnabled !== null) {
            dispatch(putSetting({
                key: "AppTheme_FlagsEnabled",
                value: newFlagsEnabled ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newFlagsEnabled]);

    // Flags style setting
    const [newFlagsStyle, setNewFlagsStyle] = useState();
    useEffect(() => {
        if (newFlagsStyle) {
            dispatch(putSetting({
                key: "AppTheme_FlagStyle",
                value: newFlagsStyle,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newFlagsStyle]);

    // Orange flags enabled setting
    const [newOrangeFlagsEnabled, setNewOrangeFlagsEnabled] = useState(null);
    useEffect(() => {
        if (newOrangeFlagsEnabled !== null) {
            dispatch(putSetting({
                key: "AppTheme_Show_OrangeFlags",
                value: newOrangeFlagsEnabled ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newOrangeFlagsEnabled]);

    // Tjl enabled setting
    const [newTjlEnabled, setNewTjlEnabled] = useState(null);
    useEffect(() => {
        if (newTjlEnabled !== null) {
            dispatch(putSetting({
                key: "AppTheme_TjlEnabled",
                value: newTjlEnabled ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newTjlEnabled]);

    // Tjl style setting
    const [newTjlStyle, setNewTjlStyle] = useState();
    useEffect(() => {
        if (newTjlStyle) {
            dispatch(putSetting({
                key: "AppTheme_TjlStyle",
                value: newTjlStyle,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newTjlStyle]);

    return (
        <ContentLoader successCondition={defaultThemeStatus === "success"} errorCondition={defaultThemeStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={6}>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_PRIMARY_COLOR}</FormLabel>
                            <HexColorPicker color={defaultTheme?.primaryColor} onChange={(e) => {
                                setPrimaryColorChanged(true);
                                setNewPrimaryColor(e);
                            }} />
                            <TextField
                                fullWidth
                                defaultValue={defaultTheme?.primaryColor}
                                onChange={(e) => {
                                    setPrimaryColorChanged(true);
                                    setNewPrimaryColor(e.target.value)
                                }}
                                variant="standard"
                                helperText={resourceFinal[appLanguage].admin.THEME_STG_PRIMARY_COLOR_HTEXT}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_SECONDARY_COLOR}</FormLabel>
                            <HexColorPicker color={defaultTheme?.secondaryColor} onChange={(e) => {
                                setSecondaryColorChanged(true);
                                setNewSecondaryColor(e);
                            }} />
                            <TextField
                                fullWidth
                                defaultValue={defaultTheme?.secondaryColor}
                                onChange={(e) => {
                                    setSecondaryColorChanged(true);
                                    setNewSecondaryColor(e.target.value)
                                }}
                                variant="standard"
                                helperText={resourceFinal[appLanguage].admin.THEME_STG_SECONDARY_COLOR_HTEXT}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_LOGO}</FormLabel>
                            <Button
                                sx={{ width: 1, height: 1 }}
                                variant="outlined"
                                component="label"
                                color="secondary"
                            >
                                {newBannerLogoName ? newBannerLogoName : resourceFinal[appLanguage].admin.THEME_STG_LOGO}
                                <input
                                    type="file"
                                    name="image"
                                    onChange={handleImageChange}
                                    hidden
                                />
                            </Button>
                            <FormHelperText>{resourceFinal[appLanguage].admin.THEME_STG_LOGO_HTEXT}</FormHelperText>
                            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{errorImage}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex' }}>
                        <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_FLAGS}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={defaultTheme?.flagsEnabled} onChange={(e) => setNewFlagsEnabled(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.THEME_STG_FLAGS_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.THEME_STG_FLAGS_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_FLAGS_STYLE}</FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    disabled={!defaultTheme?.flagsEnabled}
                                    value={defaultTheme?.flagStyle}
                                    onChange={(e) => setNewFlagsStyle(e.target.value)}
                                    variant="standard"
                                >
                                    <MenuItem value={"Flag"}>Flag</MenuItem>
                                    <MenuItem value={"Face"}>Face</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText>{resourceFinal[appLanguage].admin.THEME_STG_FLAGS_STYLE_HTEXT}</FormHelperText>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_ORANGE_FLAGS}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox disabled={!defaultTheme?.flagsEnabled} defaultChecked={defaultTheme?.showOrangeFlags} onChange={(e) => setNewOrangeFlagsEnabled(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.THEME_STG_ORANGE_FLAGS_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.THEME_STG_ORANGE_FLAGS_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex' }}>
                        <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_TJL}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox disabled={!defaultTheme?.flagsEnabled} defaultChecked={defaultTheme?.tjlEnabled} onChange={(e) => setNewTjlEnabled(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.THEME_STG_TJL_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.THEME_STG_TJL_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.THEME_STG_TJL_STYLE}</FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    disabled={!defaultTheme?.flagsEnabled || !defaultTheme?.tjlEnabled}
                                    value={defaultTheme?.tjlStyle}
                                    onChange={(e) => setNewTjlStyle(e.target.value)}
                                    variant="standard"
                                >
                                    <MenuItem value={"Cup"}>Cup</MenuItem>
                                    <MenuItem value={"Apple"}>Apple</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText>{resourceFinal[appLanguage].admin.THEME_STG_TJL_STYLE_HTEXT}</FormHelperText>
                        </Stack>
                    </Grid>
                </Grid>
            </ Box>
        } />
    );
}
