import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import PlanningAccordionDisplay from './accordion/PlanningAccordionDisplay';
import { fetchNewPlanningActivities, fetchPlanningPeriods } from './planningSlice';

export default function PlanningPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchNewPlanningActivities(true));
        dispatch(fetchPlanningPeriods());
    }, [dispatch]);

    return (
        <PlanningAccordionDisplay />
    );
}
