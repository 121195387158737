import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Pagination, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import NoContentDisplay from '../subcomponents/NoContentDisplay';
import PageLayout from '../subcomponents/PageLayout';
import NotificationsCard from './NotificationsCard';
import { fetchNotifications } from './notificationSlice';

export default function NotificationsPage() {
    const amount = 5;
    const dispatch = useDispatch();

    const { student } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { notifications, notificationsStatus, archiveCounter } = useSelector((state) => state.notifications);
    const { appSettings } = useSelector((state) => state.settings.app);

    const [showArchived, setShowArchived] = useState(false);
    const [itemsLeft, setItemsLeft] = useState(0);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    useEffect(() => {
        if (notificationsStatus === "success") {
            setPage(1);
            const size = notifications?.filter((item) => item.archive === showArchived).length;
            setItemsLeft(size - amount);
        };
    }, [showArchived, notificationsStatus, amount, notifications]);

    useEffect(() => {
        setPages(Math.ceil(itemsLeft / amount) + 1);
    }, [itemsLeft, amount]);

    useEffect(() => {
        if (student !== null) {
            dispatch(fetchNotifications());
        };
    }, [dispatch, student, archiveCounter]);

    const handleArchiveChange = () => {
        setShowArchived(!showArchived);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    function requestNotificationAccess() {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted, if so, create a notification
            const notification = new Notification(`${resourceFinal[appLanguage].messages.NOTIFICATIONS_ALLOWED}`);
        } else if (Notification.permission !== "denied" || Notification.permission === "blocked" || Notification.permission === "default") {
            // Ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, create a notification
                if (permission === "granted") {
                    const notification = new Notification(`${resourceFinal[appLanguage].messages.NOTIFICATIONS_ALLOWED}`);
                }
            });
        };
    };

    const FilterFragment = () => {
        return (
            <FormGroup sx={{ display: 'flex', width: 1 }}>
                <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={showArchived} onChange={handleArchiveChange} />} label={`${resourceFinal[appLanguage].messages.MESSAGES_SHOW_ARCHIVED}`} />
            </FormGroup>
        );
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.MESSAGES} content={
            <ContentLoader successCondition={notificationsStatus === "success"} errorCondition={notificationsStatus === "failed"} content={
                <>
                    <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                        {(appSettings?.notificationEnabled && Notification.permission !== "granted") && <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {Notification.permission !== "denied" ?
                                <Button variant="outlined" color="secondary" sx={{ mt: 2 }} onClick={() => requestNotificationAccess()}>{resourceFinal[appLanguage].messages.NOTIFICATIONS_ALLOW}</Button>
                                :
                                <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.NOTIFICATIONS_BLOCKED}</Typography>
                            }
                        </Grid>}
                        {!showArchived && notifications?.filter((item) => item.read === false).slice((page - 1) * amount, page * amount).map((item) =>
                            <Grid item key={item.id} xs={12} md={8}>
                                <NotificationsCard notification={item} />
                            </Grid>)}

                        {!showArchived && notifications?.filter((item) => item.read === true && item.archive === false).slice((page - 1) * amount, page * amount).map((item) =>
                            <Grid item key={item.id} xs={12} md={8}>
                                <NotificationsCard notification={item} />
                            </Grid>)}

                        {showArchived && notifications?.filter((item) => item.archive === true).slice((page - 1) * amount, page * amount).map((item) =>
                            <Grid item key={item.id} xs={12} md={8}>
                                <NotificationsCard notification={item} />
                            </Grid>)}

                        {(
                            (!showArchived && notifications?.filter((item) => item.archive === false).slice((page - 1) * amount, page * amount).length === 0) ||
                            (showArchived && notifications?.filter((item) => item.archive === true).slice((page - 1) * amount, page * amount).length === 0)
                        ) &&
                            <NoContentDisplay message={resourceFinal[appLanguage].messages.NOTIFICATIONS_NO_CONTENT} />
                        }
                    </Grid>
                    {pages > 1 && <Box sx={{ mt: 'auto', alignSelf: 'center' }}>
                        <Pagination count={pages} page={page} onChange={handlePageChange} defaultPage={1} color="secondary" />
                    </Box>}
                </>
            } />
        } contentLeft={
            < FilterSidebar filters={< FilterFragment />} />
        } bottomBar contentBottom={
            < FilterButton filters={< FilterFragment />} />
        } />
    );
}
