import { Button } from '@mui/material';
import * as FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import XLSX from 'sheetjs-style';
import { postTravelCostExport } from './reportsSlice';

export default function ExportExcel({ excelData, fileName, disabled, momentId }) {
    const dispatch = useDispatch();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);

        if (momentId) {
            dispatch(postTravelCostExport({
                momentId: momentId,
                exportedBy: user.number
            }));
        }
    };

    return (
        <Button variant="outlined" color="success" disabled={disabled} onClick={() => exportToExcel()}>
            {resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.EXCEL.toLowerCase()}
        </Button>
    );
}
