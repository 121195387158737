import { Box } from '@mui/material';
import { useSelector } from "react-redux";
import { CardMedium, HomeTileSmall } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import FlagDisplay from './FlagDisplay';

export default function FlagTile() {
    const { userProgress, userProgressStatus } = useSelector((state) => state.progress);

    return (
        <Box sx={{ ...HomeTileSmall }}>
            <Box sx={{ ...CardMedium, display: 'flex', width: 1, height: 1, boxSizing: 'border-box', alignItems: 'center' }}>
                <ContentLoader successCondition={userProgressStatus === "success"} errorCondition={userProgressStatus === "failed"} textDisabled={true} errorCompact={true} content={
                    <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
                        <FlagDisplay progressFlags={userProgress?.progressFlags} xsIconSize={40} mdIconSize={75} compact={true} />
                    </Box>
                } />
            </Box>
        </Box>
    );
}
