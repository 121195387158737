import { Box } from '@mui/material';
import { useSelector } from "react-redux";
import ErrorDisplay from './ErrorDisplay';
import LoadingDisplay from './LoadingDisplay';

export default function ContentLoader({ successCondition, errorCondition, message, content, style, textDisabled, errorCompact, customError, waitForInput }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <>
            {successCondition ? content : errorCondition ?
                <Box sx={{ ...style, display: 'flex', flex: '1 1 auto' }}>
                    {customError ? customError : <ErrorDisplay message={resourceFinal[appLanguage].messages.ERROR_MESSAGE} compact={errorCompact} />}
                </Box>
                :
                waitForInput ?
                    <></>
                    :
                    <Box sx={{ ...style, display: 'flex', flex: '1 1 auto' }}>
                        <LoadingDisplay message={message} style={style} textDisabled={textDisabled} />
                    </Box>}
        </>
    )
}