import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchSearchStudents = createAsyncThunk(
    '/api/search/students/isActive',
    async (isActive, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/search/students/${isActive}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        };
    }
);

export const fetchSearchStudent = createAsyncThunk(
    '/api/search/student/studentId',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/search/student/${studentId}/${departmentId}` : `api/search/student/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchSearchClasses = createAsyncThunk(
    '/api/search/classes/active',
    async (active, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/search/classes/${active}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchSearchClassStudents = createAsyncThunk(
    '/api/search/class/classId',
    async ({ classId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/search/class/${classId}/${departmentId}` : `api/search/class/${classId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        searchStudents: null,
        searchStudentsStatus: null,
        searchStudent: null,
        searchStudentStatus: null,
        searchClasses: null,
        searchClassesStatus: null,
        searchClassStudents: null,
        searchClassStudentsStatus: null,
        studentRegisterFilter: '',
        studentRegisterPage: 'REGISTER',
        studentRegisterParentCheck: true,
        studentRegisterReasonMode: false,
    },
    reducers: {
        setStudentRegisterFilter: (state, action) => {
            state.studentRegisterFilter = action.payload;
        },
        setStudentRegisterPage: (state, action) => {
            state.studentRegisterPage = action.payload;
        },
        setStudentRegisterParentCheck: (state) => {
            state.studentRegisterParentCheck = !state.studentRegisterParentCheck;
        },
        setStudentRegisterReasonMode: (state) => {
            state.studentRegisterReasonMode = !state.studentRegisterReasonMode;
        },
    },
    extraReducers: (builder) => {
        // Fetch Search Students
        builder
            .addCase(fetchSearchStudents.pending, (state) => {
                state.searchStudentsStatus = 'loading';
            })
            .addCase(fetchSearchStudents.fulfilled, (state, action) => {
                state.searchStudentsStatus = 'success';
                state.searchStudents = action.payload;
            })
            .addCase(fetchSearchStudents.rejected, (state) => {
                state.searchStudentsStatus = 'failed';
            });

        // Fetch Search Student
        builder
            .addCase(fetchSearchStudent.pending, (state) => {
                state.searchStudentStatus = 'loading';
            })
            .addCase(fetchSearchStudent.fulfilled, (state, action) => {
                state.searchStudent = action.payload;
                state.searchStudentStatus = 'success';
            })
            .addCase(fetchSearchStudent.rejected, (state) => {
                state.searchStudentStatus = 'failed';
            });

        // Fetch Search Classes
        builder
            .addCase(fetchSearchClasses.pending, (state) => {
                state.searchClassesStatus = 'loading';
            })
            .addCase(fetchSearchClasses.fulfilled, (state, action) => {
                state.searchClasses = action.payload;
                state.searchClassesStatus = 'success';
            })
            .addCase(fetchSearchClasses.rejected, (state) => {
                state.searchClassesStatus = 'failed';
            });

        // Fetch Search Class Students
        builder
            .addCase(fetchSearchClassStudents.pending, (state) => {
                state.searchClassStudentsStatus = 'loading';
            })
            .addCase(fetchSearchClassStudents.fulfilled, (state, action) => {
                state.searchClassStudents = action.payload;
                state.searchClassStudentsStatus = 'success';
            })
            .addCase(fetchSearchClassStudents.rejected, (state) => {
                state.searchClassStudentsStatus = 'failed';
            });
    }
});

export const { setStudentRegisterFilter, setStudentRegisterPage, setStudentRegisterParentCheck, setStudentRegisterReasonMode } = searchSlice.actions;

export default searchSlice.reducer;