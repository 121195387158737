import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, Collapse, Grid, IconButton, styled, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ListItemBackground } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton sx={{ p: 0 }} {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function MessageCard({ item }) {
    const { body, dateSent, sentFrom, sentTo, subject } = item;
    const [expanded, setExpanded] = useState(false);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ ...ListItemBackground, p: 1 }} elevation={0}>
            <Grid container spacing={1}>
                <Grid item xs={11} container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary" sx={{ whiteSpace: "nowrap", width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{subject}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" color="secondary"><strong>{resourceFinal[appLanguage].words.DATE_SENT}:</strong> {GetDate(dateSent)} ({GetTime(dateSent)})</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" color="secondary"><strong>{resourceFinal[appLanguage].words.SENDER}:</strong> {sentFrom}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" color="secondary"><strong>{resourceFinal[appLanguage].words.RECEIVER}:</strong> {sentTo}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <ExpandMore expand={expanded} onClick={handleExpandClick}>
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="h6" color="primary">{subject}</Typography>
                    <Typography variant="body1" color="secondary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body.replace(/\\n/g, '<br />')) }} />
                </CardContent>
            </Collapse>
        </Card >
    );
}
