import { Box, Grid, Pagination, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import TravelDeclarationCard from './TravelDeclarationCard';
import TravelDeclarationStatusCard from './TravelDeclarationStatusCard';
import { fetchDeclaredTravelCosts, fetchTravelCostDeclerationOptions } from './travelCostSlice';

export default function TravelDeclarePage() {
    const dispatch = useDispatch();
    const amountOption = 4;
    const amountStatus = 4;

    const { travelCostOptions, travelCostOptionsStatus, declaredTravelCosts, declaredTravelCostsStatus } = useSelector((state) => state.travelCost);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const [itemsLeftOption, setItemsLeftOption] = useState(0);
    const [pageOption, setPageOption] = useState(1);
    const [pagesOption, setPagesOption] = useState(0);

    const [itemsLeftStatus, setItemsLeftStatus] = useState(0);
    const [pageStatus, setPageStatus] = useState(1);
    const [pagesStatus, setPagesStatus] = useState(0);

    useEffect(() => {
        dispatch(fetchTravelCostDeclerationOptions());
        dispatch(fetchDeclaredTravelCosts());
    }, [dispatch]);

    // Option pagination
    useEffect(() => {
        if (travelCostOptionsStatus === "success") {
            const size = travelCostOptions?.length;
            setItemsLeftOption(size - amountOption);
        }
    }, [travelCostOptionsStatus, amountOption, travelCostOptions]);

    useEffect(() => {
        setPagesOption(Math.ceil(itemsLeftOption / amountOption) + 1);
    }, [itemsLeftOption, amountOption]);

    const handlePageOptionChange = (event, value) => {
        setPageOption(value);
    };

    // Status pagination
    useEffect(() => {
        if (declaredTravelCostsStatus === "success") {
            const size = declaredTravelCosts?.length;
            setItemsLeftStatus(size - amountStatus);
        }
    }, [declaredTravelCostsStatus, amountOption, declaredTravelCosts]);

    useEffect(() => {
        setPagesStatus(Math.ceil(itemsLeftStatus / amountStatus) + 1);
    }, [itemsLeftStatus, amountStatus]);

    const handlePageStatusChange = (event, value) => {
        setPageStatus(value);
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.TRAVEL_COSTS} ${resourceFinal[appLanguage].words.DECLARE.toLowerCase()}`} content={
            <ContentLoader successCondition={travelCostOptionsStatus === "success" && declaredTravelCostsStatus === "success"} errorCondition={travelCostOptionsStatus === "failed" || declaredTravelCostsStatus === "failed"} content={
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...PageHeader, textAlign: 'left', pb: 0 }}>{resourceFinal[appLanguage].words.DECLARATION} {resourceFinal[appLanguage].words.OPTIONS.toLowerCase()}</Typography>
                        </Grid>
                        {travelCostOptions?.slice((pageOption - 1) * amountOption, pageOption * amountOption)?.map((item) =>
                            <Grid item key={item.momentId} xs={12} md={6}>
                                <TravelDeclarationCard travelCostOption={item} />
                            </Grid>
                        )}
                        {travelCostOptions?.length === 0 &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">{resourceFinal[appLanguage].messages.TRAVEL_COST_OPTIONS_EMPTY_1}</Typography>
                                <Typography variant="subtitle1">{resourceFinal[appLanguage].messages.TRAVEL_COST_OPTIONS_EMPTY_2} {siomSettings?.travelCostHoursLimit} {resourceFinal[appLanguage].words.HOUR.toLowerCase()}</Typography>
                            </Grid>
                        }
                        {(itemsLeftOption > 0 && !isNaN(pagesOption)) &&
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                                <Pagination count={pagesOption} page={pageOption} onChange={handlePageOptionChange} defaultPage={1} color="secondary" />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...PageHeader, textAlign: 'left', pb: 0 }}>{resourceFinal[appLanguage].words.FILED} {resourceFinal[appLanguage].words.DECLARATIONS.toLowerCase()}</Typography>
                        </Grid>
                        {declaredTravelCosts?.slice((pageStatus - 1) * amountStatus, pageStatus * amountStatus)?.map((item) =>
                            <Grid item key={item.id} xs={12} md={6} sx={{ display: 'flex' }}>
                                <TravelDeclarationStatusCard declaration={item} />
                            </Grid>
                        )}
                        {declaredTravelCosts?.length === 0 &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">{resourceFinal[appLanguage].messages.TRAVEL_COST_DECLARED_EMPTY}</Typography>
                            </Grid>
                        }
                    </Grid>
                    {(itemsLeftStatus > 0 && !isNaN(pagesStatus)) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, mt: 'auto' }}>
                            <Pagination count={pagesStatus} page={pageStatus} onChange={handlePageStatusChange} defaultPage={1} color="secondary" />
                        </Box>
                    }
                </>
            } />
        } bottomBar />
    );
}
