import { Grid, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { CardHeader } from '../../app/AppStyles';
import NotificationsTestButton from '../notifications/NotificationsTestButton';

export default function AdminTestTab() {
    const [recipient, setRecipient] = useState("");
    const [message, setMessage] = useState("Test message");

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography variant="body1" sx={{ ...CardHeader }}>
                        {resourceFinal[appLanguage].words.TEST} {resourceFinal[appLanguage].words.NOTIFICATION} {resourceFinal[appLanguage].words.SEND2}
                    </Typography>
                    <TextField
                        fullWidth required
                        value={recipient}
                        label={resourceFinal[appLanguage].words.MESSAGE_RECEIVER}
                        onChange={(e) => setRecipient(e.target.value)}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth required
                        value={message}
                        label={resourceFinal[appLanguage].words.MESSAGE}
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                    />
                    <NotificationsTestButton disabled={!recipient || !message} recipient={recipient} message={message} />
                </Stack>
            </Grid>
        </Grid>
    );
}
