import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import authService from "../api-authorization/AuthorizeService";

export const fetchPlanningActivities = createAsyncThunk(
    '/api/planning/activities/showPast',
    async (showPast, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/activities/${showPast}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchDayPlanning = createAsyncThunk(
    '/api/planning/day/date',
    async (date, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/day/${date}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchWeekPlanning = createAsyncThunk(
    '/api/planning/week/date',
    async (date, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/week/${date}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchMonthPlanning = createAsyncThunk(
    '/api/planning/month/date',
    async (date, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/month/${date}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchYearPlanning = createAsyncThunk(
    '/api/planning/year',
    async (date, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = date ? `api/planning/year/${date}` : `api/planning/year/`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchPeriodPlanning = createAsyncThunk(
    '/api/planning/period',
    async (periodId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/period/${periodId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchNewPlanningActivities = createAsyncThunk(
    '/api/planning/activities/new/inProgram',
    async (inProgram, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/activities/new/${inProgram}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchPlanningPeriods = createAsyncThunk(
    '/api/planning/periods',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planning/periods`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const planningSlice = createSlice({
    name: 'planning',
    initialState: {
        planningActivities: null,
        planningActivitiesStatus: null,
        dayPlanning: null,
        dayPlanningStatus: null,
        weekPlanning: null,
        weekPlanningStatus: null,
        monthPlanning: null,
        monthPlanningStatus: null,
        yearPlanning: null,
        yearPlanningStatus: null,
        periodPlanning: null,
        periodPlanningStatus: null,
        newPlanningActivities: null,
        newPlanningActivitiesStatus: null,
        planningPeriods: null,
        planningPeriodsStatus: null,
        accordionDisplay: "AccordionActivity",
        accordionSelectedDate: dayjs().toString(),
        accordionShowPast: false,
    },
    reducers: {
        setAccordionDisplay: (state, action) => {
            state.accordionDisplay = action.payload;
        },
        setAccordionSelectedDate: (state, action) => {
            state.accordionSelectedDate = action.payload;
        },
        setAccordionShowPast: (state, action) => {
            state.accordionShowPast = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Get activities
        builder
            .addCase(fetchPlanningActivities.pending, (state) => {
                state.planningActivitiesStatus = 'loading';
            })
            .addCase(fetchPlanningActivities.fulfilled, (state, action) => {
                state.planningActivities = action.payload;
                state.planningActivitiesStatus = 'success';
            })
            .addCase(fetchPlanningActivities.rejected, (state) => {
                state.planningActivitiesStatus = 'failed';
            });

        // Get day planning
        builder
            .addCase(fetchDayPlanning.pending, (state) => {
                state.dayPlanningStatus = 'loading';
            })
            .addCase(fetchDayPlanning.fulfilled, (state, action) => {
                state.dayPlanning = action.payload;
                state.dayPlanningStatus = 'success';
            })
            .addCase(fetchDayPlanning.rejected, (state) => {
                state.dayPlanningStatus = 'failed';
            });

        // Get week planning
        builder
            .addCase(fetchWeekPlanning.pending, (state) => {
                state.weekPlanningStatus = 'loading';
            })
            .addCase(fetchWeekPlanning.fulfilled, (state, action) => {
                state.weekPlanning = action.payload;
                state.weekPlanningStatus = 'success';
            })
            .addCase(fetchWeekPlanning.rejected, (state) => {
                state.weekPlanningStatus = 'failed';
            });

        // Get month planning
        builder
            .addCase(fetchMonthPlanning.pending, (state) => {
                state.monthPlanningStatus = 'loading';
            })
            .addCase(fetchMonthPlanning.fulfilled, (state, action) => {
                state.monthPlanning = action.payload;
                state.monthPlanningStatus = 'success';
            })
            .addCase(fetchMonthPlanning.rejected, (state) => {
                state.monthPlanningStatus = 'failed';
            });

        // Get year planning
        builder
            .addCase(fetchYearPlanning.pending, (state) => {
                state.yearPlanningStatus = 'loading';
            })
            .addCase(fetchYearPlanning.fulfilled, (state, action) => {
                state.yearPlanning = action.payload;
                state.yearPlanningStatus = 'success';
            })
            .addCase(fetchYearPlanning.rejected, (state) => {
                state.yearPlanningStatus = 'failed';
            });

        // Get period planning
        builder
            .addCase(fetchPeriodPlanning.pending, (state) => {
                state.periodPlanningStatus = 'loading';
            })
            .addCase(fetchPeriodPlanning.fulfilled, (state, action) => {
                state.periodPlanning = action.payload;
                state.periodPlanningStatus = 'success';
            })
            .addCase(fetchPeriodPlanning.rejected, (state) => {
                state.periodPlanningStatus = 'failed';
            });


        // Get new activities
        builder
            .addCase(fetchNewPlanningActivities.pending, (state) => {
                state.newPlanningActivitiesStatus = 'loading';
            })
            .addCase(fetchNewPlanningActivities.fulfilled, (state, action) => {
                state.newPlanningActivities = action.payload;
                state.newPlanningActivitiesStatus = 'success';
            })
            .addCase(fetchNewPlanningActivities.rejected, (state) => {
                state.newPlanningActivitiesStatus = 'failed';
            });

        // Get periods
        builder
            .addCase(fetchPlanningPeriods.pending, (state) => {
                state.planningPeriodsStatus = 'loading';
            })
            .addCase(fetchPlanningPeriods.fulfilled, (state, action) => {
                state.planningPeriods = action.payload;
                state.planningPeriodsStatus = 'success';
            })
            .addCase(fetchPlanningPeriods.rejected, (state) => {
                state.planningPeriodsStatus = 'failed';
            });
    }
});

export const {
    setAccordionDisplay,
    setAccordionSelectedDate,
    setAccordionShowPast
} = planningSlice.actions;

export default planningSlice.reducer;