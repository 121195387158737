import EuroIcon from '@mui/icons-material/Euro';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPayLaterPerformance } from '../register/registerSlice';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { postPayment, resetPaymentPost } from './mollieSlice';

export default function DelayedPaymentButton({ isReservation, performanceId, subscriptionId }) {
    const dispatch = useDispatch();

    const { payLaterPerformance, payLaterPerformanceStatus } = useSelector((state) => state.register);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { paymentResult, paymentResultStatus } = useSelector((state) => state.mollie);
    const { user } = useSelector((state) => state.user);

    // fetch payLaterPerformance info
    useEffect(() => {
        dispatch(fetchPayLaterPerformance({ performanceId: performanceId }));
    }, [dispatch, performanceId]);

    // Open mollie window if new payment is successfully made
    useEffect(() => {
        if (paymentResultStatus === "success") {
            window.open(paymentResult.links.checkout.href, "_self");
        } else if (paymentResultStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.PAYMENT_CREATION_ERROR}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPaymentPost());
        };
    }, [paymentResultStatus])

    const idealClick = () => dispatch(postPayment({
        StudentId: user.studentId,
        PerformanceId: performanceId,
        DepartmentId: siomSettings?.useMultipleDepartments ? activeDepartment[0]?.id : null,
        CreatedBy: `AP/${user?.number}`,
        amount: payLaterPerformance?.cost,
        description: `${payLaterPerformance?.activity.name}${payLaterPerformance?.performanceName ? ` - ${payLaterPerformance?.performanceName}` : ""} - ${user?.number} - ${payLaterPerformance?.performanceNumber}`,
        redirectUrl: `${window.Config.apiUrl}inschrijven/betaling/`,
        reservationExists: true,
        reservationId: subscriptionId
    }));

    return (
        <>
            {(siomSettings?.mollieActive && siomSettings?.mollieEnableDelay && isReservation && payLaterPerformanceStatus === "success") &&
                <Button variant="outlined" color="success" sx={{ width: 110 }} startIcon={<EuroIcon />} onClick={idealClick}>
                    {resourceFinal[appLanguage].words.PAY}
                </Button>
            }
        </>
    );
}
