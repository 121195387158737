import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, ButtonGroup, Card, IconButton, Modal, Typography } from '@mui/material/';
import { useState } from 'react';
import { useSelector } from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 400 },
    boxShadow: 24,
};

export default function ConfirmationModal({ iconButton, icon, buttonText, buttonStyle, buttonColor, disabledCondition, message, confirmAction, customField }) {
    const [open, setOpen] = useState(false);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {iconButton ? (
                <IconButton color={buttonColor} disabled={disabledCondition} onClick={handleOpen} sx={{ ...buttonStyle }}>{icon}</IconButton>
            ) : (
                <Button variant="outlined" color={buttonColor} disabled={disabledCondition} onClick={handleOpen} sx={{ ...buttonStyle }}>{buttonText}</Button>
            )}
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Card sx={{ p: 3, textAlign: 'center' }}>
                        <Typography variant="h6">{message}</Typography>
                        {customField &&
                            <Box sx={{ mt: 1 }}>
                                {customField}
                            </Box>
                        }
                        <ButtonGroup color="secondary" sx={{ mt: 2 }}>
                            <Button color="error" startIcon={<CloseIcon />} onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                            <Button color="success" endIcon={<CheckIcon />} onClick={() => {
                                confirmAction();
                                handleClose();
                            }}>{resourceFinal[appLanguage].words.CONFIRM}</Button>
                        </ButtonGroup>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}