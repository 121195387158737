import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FlagIcon from '@mui/icons-material/Flag';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Badge } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

function AppleIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M20,10C22,13 17,22 15,22C13,22 13,21 12,21C11,21 11,22 9,22C7,22 2,13 4,10C6,7 9,7 11,8V5C5.38,8.07 4.11,3.78 4.11,3.78C4.11,3.78 6.77,0.19 11,5V3H13V8C15,7 18,7 20,10Z" />
        </SvgIcon>
    );
};

export default function Flag({ count, style, type, tjl, badgePosition, settings }) {
    return (
        <>
            {tjl === true ?
                <>
                    {settings.tjlStyle === 'Cup' ? <EmojiEventsIcon sx={{ ...style }} /> : ""}
                    {settings.tjlStyle === 'Apple' ? <AppleIcon sx={{ ...style }} /> : ""}
                </>
                :
                <Badge badgeContent={count} color="secondary" overlap="circular" anchorOrigin={badgePosition} >
                    {settings.flagStyle === 'Flag' ? <FlagIcon sx={{ ...style }} /> : ""}
                    {settings.flagStyle === 'Face' ?
                        <>
                            {type === 'red' ? <SentimentVeryDissatisfiedIcon sx={{ ...style }} /> : ""}
                            {type === 'yellow' ? <SentimentNeutralIcon sx={{ ...style }} /> : ""}
                            {type === 'green' ? <SentimentSatisfiedAltIcon sx={{ ...style }} /> : ""}
                        </>
                        : ""}
                </Badge>
            }
        </>
    );
}
