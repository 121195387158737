import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { GetDate } from '../../app/AppUtils';
import ListItemCard from '../subcomponents/ListItemCard';

import CakeIcon from '@mui/icons-material/Cake';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';

export default function EmployeeInfoContent() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user } = useSelector((state) => state.user);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={user?.number}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_STUDENTNUMBER}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PersonIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={`${user?.firstName} ${user?.middleName} ${user?.lastName}`}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_NAME}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<CakeIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={GetDate(user?.dateOfBirth)}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<EmailIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={user?.workEmail}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_SCHOOL}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PhoneIcon />}
                        avatarColor={'secondary.main'}
                        altBgColor={{ bgcolor: 'white.main' }}
                        primaryText={user?.privatePhone ? user?.privatePhone : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}

                    />
                </Grid>
            </Grid>
        </>
    );
}
