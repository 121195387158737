import ApartmentIcon from '@mui/icons-material/Apartment';
import { alpha, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { updateLastUsedDepartment } from '../settings/themeSettingsSlice';
import NavMenuItem from '../subcomponents/NavMenuItem';
import DepartmentCard from './DepartmentCard';

export default function DepartmentPicker({ type }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { departmentThemes, activeDepartment } = useSelector((state) => state.settings.theme);

    const departmentClick = (event) => {
        dispatch(updateLastUsedDepartment(event.target.value.id));
        navigate(0);
    };

    const departmentMenuClick = (id) => {
        dispatch(updateLastUsedDepartment(id));
        navigate(0);
    };

    return (
        <>
            {departmentThemes?.length > 1 ?
                <>
                    {type === "compact" ?
                        <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 180 }} size="small">
                            <InputLabel color="white" sx={{ color: 'white.light', bgcolor: 'primary.main' }}>{resourceFinal[appLanguage].words.DEPARTMENT}</InputLabel>
                            <Select
                                value={activeDepartment ? activeDepartment[0] : ""}
                                onChange={departmentClick}
                                color="white"
                                sx={{
                                    color: 'white.light',
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}
                            >
                                {departmentThemes?.map((item) =>
                                    <MenuItem key={item.id} value={item} sx={{ color: item.themeSettings.primaryColor }}>{item.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        : type === "menu" ?
                            <>
                                <Divider />
                                {departmentThemes?.map((item) =>
                                    <NavMenuItem key={item.id} text={item.name} textStyle={{ color: item.themeSettings.primaryColor }} itemStyle={{ bgcolor: activeDepartment[0].name === item.name ? theme => alpha(theme.palette.primary.light, 0.5) : 'white.light' }} icon={<ApartmentIcon color="primary" />} clickFn={() => departmentMenuClick(item.id)} />
                                )}
                            </>
                            :
                            <Grid container spacing={1} justifyContent="center">
                                {departmentThemes?.map((item) =>
                                    <Grid item key={item.id} xs={6} md={3}>
                                        <DepartmentCard department={item} />
                                    </Grid>
                                )}
                            </Grid>
                    }
                </>
                : ""}
        </>
    );
}
