import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchMolliePayment = createAsyncThunk(
    '/api/mollie/molliePaymentId',
    async (molliePaymentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/${molliePaymentId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchTransaction = createAsyncThunk(
    '/api/mollie/transaction/transactionId',
    async (transactionId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/transaction/${transactionId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putMolliePayment = createAsyncThunk(
    '/api/mollie/update/molliePaymentId',
    async (molliePaymentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/update/${molliePaymentId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putTransaction = createAsyncThunk(
    '/api/mollie/update/transaction',
    async (updatedTransaction, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/update/transaction`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedTransaction)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putReservation = createAsyncThunk(
    '/api/mollie/confirm/reservation',
    async (reservation, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/confirm/reservation`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(reservation)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteReservation = createAsyncThunk(
    '/api/mollie/delete/reservation',
    async (reservation, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/delete/reservation`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
            body: JSON.stringify(reservation)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postPayment = createAsyncThunk(
    '/api/mollie/post',
    async (paymentInfo, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(paymentInfo)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postReservation = createAsyncThunk(
    '/api/mollie/reservation/post',
    async (paymentInfo, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/mollie/reservation`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(paymentInfo)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const mollieSlice = createSlice({
    name: 'mollie',
    initialState: {
        transaction: null,
        transactionStatus: null,
        molliePayment: null,
        molliePaymentStatus: null,
        updateTransactionStatus: null,
        updateMolliePaymentStatus: null,
        confirmReservationStatus: null,
        deleteReservationStatus: null,
        paymentResult: null,
        paymentResultStatus: null,
        postReservationStatus: null
    },
    reducers: {
        resetPaymentPost: (state) => {
            state.paymentResult = null;
            state.paymentResultStatus = null;
        },
        resetPostReservation: (state) => {
            state.postReservationStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Fetch Mollie payment
        builder
            .addCase(fetchMolliePayment.pending, (state) => {
                state.molliePaymentStatus = 'loading';
            })
            .addCase(fetchMolliePayment.fulfilled, (state, action) => {
                state.molliePaymentStatus = 'success';
                state.molliePayment = action.payload;
            })
            .addCase(fetchMolliePayment.rejected, (state) => {
                state.molliePaymentStatus = 'failed';
            });

        // Fetch transaction
        builder
            .addCase(fetchTransaction.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(fetchTransaction.fulfilled, (state, action) => {
                state.transactionStatus = 'success';
                state.transaction = action.payload;
            })
            .addCase(fetchTransaction.rejected, (state) => {
                state.transactionStatus = 'failed';
            });

        // Put Mollie payment
        builder
            .addCase(putMolliePayment.pending, (state) => {
                state.updateMolliePaymentStatus = 'loading';
            })
            .addCase(putMolliePayment.fulfilled, (state) => {
                state.updateMolliePaymentStatus = 'success';
            })
            .addCase(putMolliePayment.rejected, (state) => {
                state.updateMolliePaymentStatus = 'failed';
            });

        // Put transaction
        builder
            .addCase(putTransaction.pending, (state) => {
                state.updateTransactionStatus = 'loading';
            })
            .addCase(putTransaction.fulfilled, (state) => {
                state.updateTransactionStatus = 'success';
            })
            .addCase(putTransaction.rejected, (state) => {
                state.updateTransactionStatus = 'failed';
            });

        // Put reservation
        builder
            .addCase(putReservation.pending, (state) => {
                state.confirmReservationStatus = 'loading';
            })
            .addCase(putReservation.fulfilled, (state) => {
                state.confirmReservationStatus = 'success';
            })
            .addCase(putReservation.rejected, (state) => {
                state.confirmReservationStatus = 'failed';
            });

        // Delete reservation
        builder
            .addCase(deleteReservation.pending, (state) => {
                state.deleteReservationStatus = 'loading';
            })
            .addCase(deleteReservation.fulfilled, (state) => {
                state.deleteReservationStatus = 'success';
            })
            .addCase(deleteReservation.rejected, (state) => {
                state.deleteReservationStatus = 'failed';
            });

        // Post payment
        builder
            .addCase(postPayment.pending, (state) => {
                state.paymentResultStatus = 'loading';
            })
            .addCase(postPayment.fulfilled, (state, action) => {
                state.paymentResultStatus = 'success';
                state.paymentResult = action.payload;
            })
            .addCase(postPayment.rejected, (state) => {
                state.paymentResultStatus = 'failed';
            });

        // Post reservation
        builder
            .addCase(postReservation.pending, (state) => {
                state.postReservationStatus = 'loading';
            })
            .addCase(postReservation.fulfilled, (state) => {
                state.postReservationStatus = 'success';
            })
            .addCase(postReservation.rejected, (state) => {
                state.postReservationStatus = 'failed';
            });
    }
});

export const { resetPaymentPost, resetPostReservation } = mollieSlice.actions;

export default mollieSlice.reducer;