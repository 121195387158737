import { Backdrop, Button, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setPermission } from './notificationSlice';

export default function NotificationsWarning() {
    const dispatch = useDispatch();
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { permission } = useSelector((state) => state.notifications);

    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if ("Notification" in window) {
            dispatch(setPermission(Notification.permission));
        }
    }, [dispatch])

    function requestNotificationAccess() {
        setClicked(true);

        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted, if so, create a notification
            const notification = new Notification(`${resourceFinal[appLanguage].messages.NOTIFICATIONS_ALLOWED}`);
        } else if (Notification.permission !== "denied" || Notification.permission === "blocked" || Notification.permission === "default") {
            // Ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, create a notification
                dispatch(setPermission(permission));
                if (permission === "granted") {
                    const notification = new Notification(`${resourceFinal[appLanguage].messages.NOTIFICATIONS_ALLOWED}`);
                }
            });
        };
    };

    return (
        <>
            {permission !== "granted" &&
                <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Card sx={{ height: 'auto', mx: 2, textAlign: 'center', maxWidth: { xs: '90%', md: '50%' } }} >
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {permission === "denied" || permission === "blocked" ?
                                <>
                                    <Typography variant="h6">{resourceFinal[appLanguage].messages.NOTIFICATIONS_BLOCKED}</Typography>
                                    {!("Notification" in window) && <Typography variant="body1">{resourceFinal[appLanguage].messages.NOTIFICATIONS_DENIED}</Typography>}
                                </>
                                :
                                <>
                                    <Typography variant="h6">{resourceFinal[appLanguage].messages.NOTIFICATIONS_REQUIRED}</Typography>
                                    {clicked && <Typography variant="body1">{resourceFinal[appLanguage].messages.NOTIFICATIONS_TURNED_OFF}</Typography>}
                                    {!("Notification" in window) ?
                                        <>
                                            <Typography variant="body1">{resourceFinal[appLanguage].messages.NOTIFICATIONS_DENIED}</Typography>
                                        </>
                                        : permission === "blocked" ?
                                            <>
                                                <Typography variant="h6">{resourceFinal[appLanguage].messages.NOTIFICATIONS_BLOCKED}</Typography>
                                            </>
                                            :
                                            <>
                                                <Button disabled={clicked} variant="outlined" color="secondary" sx={{ mt: 2 }} onClick={() => requestNotificationAccess()}>{resourceFinal[appLanguage].messages.NOTIFICATIONS_ALLOW}</Button>
                                            </>
                                    }
                                </>
                            }
                        </CardContent>
                    </Card>
                </Backdrop>}
        </>
    );
}
