import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from "react-redux";
import { CardLarge } from '../../app/AppStyles';

export default function FilterButton({ filters }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Modal open={open} onClose={handleClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card sx={{ ...CardLarge, bgcolor: 'white.main', p: 1, m: 2 }}>
                    <Stack spacing={1} alignItems="center" sx={{ flex: '1 1 auto', p: 1 }} >
                        <Typography variant="h5" color="primary">{resourceFinal[appLanguage].words.FILTERS}</Typography>
                        {filters}
                    </Stack>
                </Card>
            </Modal>
            <IconButton onClick={handleOpen}>
                <FilterAltIcon sx={{ color: 'white.main', fontSize: 32 }} />
            </IconButton>
        </>
    );
}