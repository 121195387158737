import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchRegister = createAsyncThunk(
    '/api/register/user',
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/register/user/${departmentId}` : `api/register/user`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchRegisterForEmployee = createAsyncThunk(
    '/api/register/employee',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/register/employee/${studentId}/${departmentId}` : `api/register/employee/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchAllRegisterForEmployee = createAsyncThunk(
    '/api/register/employee/all',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/register/employee/all/${studentId}/${departmentId}` : `api/register/employee/all/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchPerformance = createAsyncThunk(
    '/api/register/performance',
    async ({ performanceId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/register/performance/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchPayLaterPerformance = createAsyncThunk(
    '/api/register/payLaterPerformance',
    async ({ performanceId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/register/performance/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchPeriods = createAsyncThunk(
    '/api/register/periods',
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/register/periods/${departmentId}` : `api/register/periods`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchPeriodsForEmployee = createAsyncThunk(
    '/api/register/periods/employee/studentId',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/register/periods/employee/${studentId}/${departmentId}` : `api/register/periods/employee/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postSubscription = createAsyncThunk(
    '/api/register/post',
    async ({ studentId, performanceId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = studentId === null ? `api/register/student` : `api/register/employee`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({
                studentId: studentId, performanceId: performanceId, departmentId: departmentId
            }),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteSubscriptionStudent = createAsyncThunk(
    '/api/register/delete',
    async (unsubscription, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/register`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
            body: JSON.stringify(unsubscription),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const registerSlice = createSlice({
    name: 'register',
    initialState: {
        register: null,
        registerStatus: null,
        registerAll: null,
        registerAllStatus: null,
        performance: null,
        performanceStatus: null,
        performances: null,
        performancesStatus: null,
        subscriptionStatus: null,
        deleteStatus: null,
        periods: null,
        periodsStatus: null,
        parentCheck: true,
        payLaterPerformance: null,
        payLaterPerformanceStatus: null,
    },
    reducers: {
        resetRegisterStatusses: (state, action) => {
            state.registerStatus = null;
            state.registerAllStatus = null;
            state.performanceStatus = null;
            state.performancesStatus = null;
            state.subscriptionStatus = null;
            state.deleteStatus = null;
        },
        setPerformance: (state, action) => {
            state.performance = action.payload;
            state.performanceStatus = "success";
        },
        setPerformances: (state, action) => {
            state.performances = action.payload;
            state.performancesStatus = "success";
        },
        setCheckedPeriod: (state, action) => {
            var objIndex = state.periods.findIndex((obj => obj.periodId === action.payload));
            state.periods[objIndex].checked = !state.periods[objIndex].checked;
        },
        setAllPeriodChecks: (state, action) => {
            var objIndex = state.periods.findIndex((obj => obj.periodId === action.payload.id));
            state.periods[objIndex].checked = action.payload.bool;
        },
        setParentCheck: (state) => {
            state.parentCheck = !state.parentCheck;
        },

    },
    extraReducers: (builder) => {
        // Register
        builder
            .addCase(fetchRegister.pending, (state) => {
                state.registerStatus = 'loading';
            })
            .addCase(fetchRegister.fulfilled, (state, action) => {
                state.registerStatus = 'success';
                state.register = action.payload;
            })
            .addCase(fetchRegister.rejected, (state, action) => {
                state.registerStatus = 'failed';
                state.register = action.payload;
            });

        // Register for employee
        builder
            .addCase(fetchRegisterForEmployee.pending, (state) => {
                state.registerStatus = 'loading';
            })
            .addCase(fetchRegisterForEmployee.fulfilled, (state, action) => {
                state.registerStatus = 'success';
                state.register = action.payload;
            })
            .addCase(fetchRegisterForEmployee.rejected, (state) => {
                state.registerStatus = 'failed';
            });

        // All register for employee
        builder
            .addCase(fetchAllRegisterForEmployee.pending, (state) => {
                state.registerAllStatus = 'loading';
            })
            .addCase(fetchAllRegisterForEmployee.fulfilled, (state, action) => {
                state.registerAllStatus = 'success';
                state.registerAll = action.payload;
            })
            .addCase(fetchAllRegisterForEmployee.rejected, (state) => {
                state.registerAllStatus = 'failed';
            });
        
        // Performance
        builder
            .addCase(fetchPerformance.pending, (state) => {
                state.performanceStatus = 'loading';
            })
            .addCase(fetchPerformance.fulfilled, (state, action) => {
                state.performanceStatus = 'success';
                state.performance = action.payload;
            })
            .addCase(fetchPerformance.rejected, (state, action) => {
                state.performanceStatus = 'failed';
            });

        // Pay Later Performance
        builder
            .addCase(fetchPayLaterPerformance.pending, (state) => {
                state.payLaterPerformanceStatus = 'loading';
            })
            .addCase(fetchPayLaterPerformance.fulfilled, (state, action) => {
                state.payLaterPerformanceStatus = 'success';
                state.payLaterPerformance = action.payload;
            })
            .addCase(fetchPayLaterPerformance.rejected, (state, action) => {
                state.payLaterPerformanceStatus = 'failed';
            });

        // Periods
        builder
            .addCase(fetchPeriods.pending, (state) => {
                state.periodsStatus = 'loading';
            })
            .addCase(fetchPeriods.fulfilled, (state, action) => {
                state.periodsStatus = 'success';
                state.periods = action.payload;
            })
            .addCase(fetchPeriods.rejected, (state) => {
                state.periodsStatus = 'failed';
            });

        // Periods for employee
        builder
            .addCase(fetchPeriodsForEmployee.pending, (state) => {
                state.periodsStatus = 'loading';
            })
            .addCase(fetchPeriodsForEmployee.fulfilled, (state, action) => {
                state.periodsStatus = 'success';
                state.periods = action.payload;
            })
            .addCase(fetchPeriodsForEmployee.rejected, (state) => {
                state.periodsStatus = 'failed';
            });

        // Post Subscription
        builder
            .addCase(postSubscription.rejected, (state) => {
                state.subscriptionStatus = 'failed';
            })
            .addCase(postSubscription.fulfilled, (state) => {
                state.subscriptionStatus = 'success';
            })
            .addCase(postSubscription.pending, (state) => {
                state.subscriptionStatus = 'loading';
            });

        // Delete Subscription
        builder
            .addCase(deleteSubscriptionStudent.rejected, (state) => {
                state.deleteStatus = 'failed';
            })
            .addCase(deleteSubscriptionStudent.fulfilled, (state) => {
                state.deleteStatus = 'success';
            })
            .addCase(deleteSubscriptionStudent.pending, (state) => {
                state.deleteStatus = 'loading';
            });
    }
});

export const {
    resetRegisterStatusses,
    setAllPeriodChecks,
    setCheckedPeriod,
    setParentCheck,
    setPerformance,
    setPerformances
} = registerSlice.actions;

export default registerSlice.reducer;