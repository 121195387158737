import { Backdrop, Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApplicationPaths } from '../../components/api-authorization/ApiAuthorizationConstants';
import MobileBlockWarning from '../../components/misc/MobileBlockWarning';
import { setActiveDepartment, updateLastUsedDepartment } from '../../components/settings/themeSettingsSlice';
import UserWarning from '../../components/student/UserWarning';

export default function EmployeeChecks() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment, departmentThemes, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { userCheck, userCheckStatus, userStatus } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        if (userCheck === 4 && siomSettings.useMultipleDepartments && departmentThemesStatus === "success" && activeDepartment === null) {
            if (departmentThemes.length > 1) {
                navigate("/afdeling-kiezer");
            } else if (departmentThemes.length !== 0) {
                dispatch(setActiveDepartment(departmentThemes))
                dispatch(updateLastUsedDepartment(departmentThemes[0]?.id));
                navigate(0);
            };
        };
    }, [departmentThemesStatus]);

    return (
        <>
            {userCheckStatus === 'success' && userStatus === 'success' ?
                <>
                    <UserWarning />
                    <Box sx={{ display: { sx: 'flex', md: 'none' } }}>
                        <MobileBlockWarning />
                    </Box>
                </>
                : userStatus === 'failed' &&
                <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Card sx={{ m: 1, px: 2, py: 1, textAlign: 'center' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ fontSize: '1.4em' }}>{resourceFinal[appLanguage].messages.ERROR_MESSAGE_GET_EMPLOYEE_FAILED}</Typography>
                            <Typography variant="h6" sx={{ fontSize: '1.2em' }}>{resourceFinal[appLanguage].messages.AUTOMATIC_REFERRAL}</Typography>
                            <Button variant="outlined" color="secondary" sx={{ mt: 2 }} onClick={() => navigate(`${ApplicationPaths.Login}`)}>{resourceFinal[appLanguage].words.LOGIN}</Button>
                        </CardContent>
                    </Card>
                </Backdrop>
            }
        </>
    );
}
