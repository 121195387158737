import { Box, Grid, Modal } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GetDate } from '../../app/AppUtils';
import ListItemCard from '../subcomponents/ListItemCard';
import StudentInfoEditCard from './StudentInfoEditCard';

import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CakeIcon from '@mui/icons-material/Cake';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import Groups2Icon from '@mui/icons-material/Groups2';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Man4Icon from '@mui/icons-material/Man4';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SchoolIcon from '@mui/icons-material/School';
import TrainIcon from '@mui/icons-material/Train';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 400 },
    boxShadow: 24,
};

export default function StudentInfoContentV1({ content, editable }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [open, setOpen] = useState(false);
    const [property, setProperty] = useState();

    const handleOpen = (_property) => {
        setOpen(true);
        setProperty(_property);
    };

    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PersonIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.initials ? `${content?.initials} (${content?.firstName}) ${content?.middleName} ${content?.lastName}` : `${content?.firstName} ${content?.middleName} ${content?.lastName}`}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_NAME}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<CakeIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={GetDate(content?.dateOfBirth)}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.number}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_STUDENTNUMBER}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<EmailIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.workEmail}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_SCHOOL}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<GroupsIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.class ? content?.extraInfo?.class : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CLASS}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<Groups2Icon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.studyYear ? content?.extraInfo?.studyYear : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_YEAR}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<Man4Icon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.mentor ? content?.extraInfo?.mentor : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_MENTOR}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<HistoryEduIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.course ? content?.extraInfo?.course : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_COURSE}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<LocationCityIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.city ? content?.extraInfo?.city : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CITY}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<AssignmentReturnedIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.extraInfo?.pokTurnedIn ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_POK}
                            action={() => handleOpen('pokTurnedIn')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<AssignmentReturnedIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.pokTurnedIn ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_POK}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<PaymentIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.extraInfo?.ibanNumber ? content?.extraInfo?.ibanNumber : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_IBAN}
                            action={() => handleOpen('ibanNumber')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<PaymentIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.ibanNumber ? content?.extraInfo?.ibanNumber : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_IBAN}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<PhoneAndroidIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.privatePhone ? content?.privatePhone : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                            action={() => handleOpen('privatePhone')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<PhoneAndroidIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.privatePhone ? content?.privatePhone : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<PhoneIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.extraInfo?.emergencyPhone ? content?.extraInfo?.emergencyPhone : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE}
                            action={() => handleOpen('workPhone')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<PhoneIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.emergencyPhone ? content?.extraInfo?.emergencyPhone : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<DirectionsCarIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.extraInfo?.drivingLicence ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_LICENCE}
                            action={() => handleOpen('drivingLicence')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<DirectionsCarIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.drivingLicence ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_LICENCE}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<TrainIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.extraInfo?.ovWeek ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEK : content?.extraInfo?.ovWeekend ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEKEND : resourceFinal[appLanguage].words.NO}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_OV}
                            action={() => handleOpen('ovSubscription')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<TrainIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.ovWeek ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEK : content?.extraInfo?.ovWeekend ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEKEND : resourceFinal[appLanguage].words.NO}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_OV}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<CoronavirusIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.extraInfo?.allergy ? content?.extraInfo?.allergy : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_ALLERGY}
                            action={() => handleOpen('allergy')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<CoronavirusIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.allergy ? content?.extraInfo?.allergy : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_ALLERGY}
                        />
                    )}
                </Grid>
            </Grid>

            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <StudentInfoEditCard studentInfo={content} property={property} />
                </Box>
            </Modal>
        </>
    );
}