import { Box, Checkbox, FormControlLabel, FormHelperText, FormLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSiomSettings } from '../settings/siomSettingsSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import useSearchDebounce from '../subcomponents/SearchDebounce';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { putSetting, resetUpdateStatus } from './adminSlice';
import Grid from '@mui/material/Unstable_Grid2';

export default function SiomSettingsTab() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings, siomSettingsStatus } = useSelector((state) => state.settings.siom);
    const { updateSettingStatus } = useSelector((state) => state.admin);

    // Put Callback
    useEffect(() => {
        if (updateSettingStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchSiomSettings());
        } else if (updateSettingStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateSettingStatus]);

    // Absence message send setting
    const [newAbsenceMessage, setNewAbsenceMessage] = useState(null);
    useEffect(() => {
        if (newAbsenceMessage !== null) {
            dispatch(putSetting({
                key: "Email_EmailBijAfwezigheidVersturen",
                value: newAbsenceMessage ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newAbsenceMessage]);

    // Absence message text setting
    const [newAbsenceSubject, setNewAbsenceSubject] = useSearchDebounce();
    useEffect(() => {
        if (newAbsenceSubject) {
            dispatch(putSetting({
                key: "Siom_Absentiebericht_Onderwerp",
                value: newAbsenceSubject,
                modifiedBy: `AP/${user.number}`
            }));
        };
    }, [dispatch, user, newAbsenceSubject]);

    // Absence message text setting
    const [newAbsenceText, setNewAbsenceText] = useSearchDebounce();
    useEffect(() => {
        if (newAbsenceText) {
            dispatch(putSetting({
                key: "Siom_Absentiebericht_Tekst",
                value: newAbsenceText,
                modifiedBy: `AP/${user.number}`
            }));
        };
    }, [dispatch, user, newAbsenceText]);

    // Default moment duration setting
    const [newMomentDefaultDuration, setNewMomentDefaultDuration] = useSearchDebounce();
    useEffect(() => {
        if (newMomentDefaultDuration) {
            dispatch(putSetting({
                key: "Siom_MomentDefaultDuration",
                value: newMomentDefaultDuration,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMomentDefaultDuration]);

    // Departments setting
    const [newDepartments, setNewDepartments] = useState(null);
    useEffect(() => {
        if (newDepartments !== null) {
            dispatch(putSetting({
                key: "Siom_UseMultipleDepartments",
                value: newDepartments ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newDepartments]);

    // Default moment duration setting
    const [newEmployeeShowAllDepartments, setNewEmployeeShowAllDepartments] = useState(null);
    useEffect(() => {
        if (newEmployeeShowAllDepartments !== null) {
            dispatch(putSetting({
                key: "Siom_EmployeeShowAllDepartments",
                value: newEmployeeShowAllDepartments ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmployeeShowAllDepartments]);

    // Flag progress setting
    const [newFlagProgress, setNewFlagProgress] = useState(null);
    useEffect(() => {
        if (newFlagProgress !== null) {
            dispatch(putSetting({
                key: "Siom_GroeneVlaggenVoortgang",
                value: newFlagProgress ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newFlagProgress]);

    // Gradual points setting
    const [newGradualPoints, setNewGradualPoints] = useState(null);
    useEffect(() => {
        if (newGradualPoints !== null) {
            dispatch(putSetting({
                key: "Siom_Gradual_Points",
                value: newGradualPoints ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newGradualPoints]);

    // Mentor contact setting
    const [newMentorContact, setNewMentorContact] = useState(null);
    useEffect(() => {
        if (newMentorContact !== null) {
            dispatch(putSetting({
                key: "Siom_Mentor_As_Contact",
                value: newMentorContact ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMentorContact]);

    // Messages setting
    const [newMessages, setNewMessages] = useState(null);
    useEffect(() => {
        if (newMessages !== null) {
            dispatch(putSetting({
                key: "Siom_Messages_Enabled",
                value: newMessages ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMessages]);

    // Mollie active setting
    const [newMollieActive, setNewMollieActive] = useState(null);
    useEffect(() => {
        if (newMollieActive !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Activate_Mollie",
                value: newMollieActive ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieActive]);

    // Mollie delay setting
    const [newMollieDelay, setNewMollieDelay] = useState(null);
    useEffect(() => {
        if (newMollieDelay !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Enable_Delay",
                value: newMollieDelay ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieDelay]);

    // Mollie hours setting
    const [newMollieHours, setNewMollieHours] = useSearchDebounce();;
    useEffect(() => {
        if (newMollieHours) {
            dispatch(putSetting({
                key: "Siom_Mollie_Delay_Hours",
                value: newMollieHours,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieHours]);

    // Mollie unsubscribe setting
    const [newMollieUnsub, setNewMollieUnsub] = useState(null);
    useEffect(() => {
        if (newMollieUnsub !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Enable_Unsubscribe",
                value: newMollieUnsub ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieUnsub]);

    // Mollie test setting
    const [newMollieTest, setNewMollieTest] = useState(null);
    useEffect(() => {
        if (newMollieTest !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Activate_Mollie_TestModus",
                value: newMollieTest ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieTest]);

    // Reservation setting
    const [newReservation, setNewReservation] = useState(null);
    useEffect(() => {
        if (newReservation !== null) {
            dispatch(putSetting({
                key: "Siom_IsReservation",
                value: newReservation ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newReservation]);

    // Show Search Register Tab setting
    const [newShowSearchRegisterTab, setNewShowSearchRegisterTab] = useState(null);
    useEffect(() => {
        if (newShowSearchRegisterTab !== null) {
            dispatch(putSetting({
                key: "Siom_ShowSearchRegisterTab",
                value: newShowSearchRegisterTab ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newShowSearchRegisterTab]);

    // Travel costs hours setting
    const [newTravelCosts, setNewTravelCosts] = useSearchDebounce();
    useEffect(() => {
        if (newTravelCosts) {
            dispatch(putSetting({
                key: "Siom_TravelCost_Hours_Limit",
                value: newTravelCosts,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newTravelCosts]);

    // Uitschrijfperiode setting
    const [newUnsubscribePeriod, setNewUnsubscribePeriod] = useSearchDebounce();
    useEffect(() => {
        if (newUnsubscribePeriod) {
            dispatch(putSetting({
                key: "Siom_Uitschrijfperiode",
                value: newUnsubscribePeriod,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newUnsubscribePeriod]);

    return (
        <ContentLoader successCondition={siomSettingsStatus === "success"} errorCondition={siomSettingsStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={1.5} direction="row">
                    <Grid item xs={12}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_ABSENCE_MSG}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.absenceSendMessage} onChange={(e) => setNewAbsenceMessage(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_ABSENCE_MSG_ON} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_ABSENCE_MSG_SUBJECT}</FormLabel>
                            <TextField
                                fullWidth
                                defaultValue={siomSettings?.absenceSubject}
                                onChange={(e) => setNewAbsenceSubject(e.target.value)}
                                variant="standard"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_ABSENCE_MSG_TEXT}</FormLabel>
                            <TextField
                                fullWidth multiline
                                defaultValue={siomSettings?.absenceText}
                                onChange={(e) => setNewAbsenceText(e.target.value)}
                                variant="outlined"
                                helperText={resourceFinal[appLanguage].admin.SIOM_STG_ABSENCE_MSG_HTEXT}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_FLAG_PROGRESS}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.flagProgress} onChange={(e) => setNewFlagProgress(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_FLAG_PROGRESS_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_FLAG_PROGRESS_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_GRAD_POINTS}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.gradualPoints} onChange={(e) => setNewGradualPoints(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_GRAD_POINTS_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_GRAD_POINTS_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MENTOR_CONTACT}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.mentorAsContact} onChange={(e) => setNewMentorContact(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_MENTOR_CONTACT_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_MENTOR_CONTACT_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MESSAGES}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.messagesEnabled} onChange={(e) => setNewMessages(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_MESSAGES_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_MESSAGES_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_ACTIVE}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.mollieActive} onChange={(e) => setNewMollieActive(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_ACTIVE_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_ACTIVE_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_TEST}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox disabled={!siomSettings?.mollieActive} defaultChecked={siomSettings?.mollieTest} onChange={(e) => setNewMollieTest(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_TEST_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_TEST_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_DELAY}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox disabled={!siomSettings?.mollieActive} defaultChecked={siomSettings?.mollieEnableDelay} onChange={(e) => setNewMollieDelay(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_DELAY_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_DELAY_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_UNSUB}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox disabled={!siomSettings?.mollieActive} defaultChecked={siomSettings?.mollieEnableUnsubscribe} onChange={(e) => setNewMollieUnsub(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_UNSUB_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_UNSUB_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_HOURS}</FormLabel>
                            <TextField disabled={!siomSettings?.mollieActive || !siomSettings?.mollieEnableDelay}
                                fullWidth type="number"
                                defaultValue={siomSettings?.mollieDelayHours}
                                inputProps={{ min: 1 }}
                                onChange={(e) => setNewMollieHours(e.target.value)}
                                variant="standard"
                                helperText={resourceFinal[appLanguage].admin.SIOM_STG_MOLLIE_HOURS_HTEXT}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex' }}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, flex: '1 1 auto' }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_MOMENT_DEFAULT_DURATION}</FormLabel>
                            <TextField
                                fullWidth type="number"
                                defaultValue={siomSettings?.momentDefaultDuration}
                                inputProps={{ min: 1 }}
                                onChange={(e) => setNewMomentDefaultDuration(e.target.value)}
                                variant="standard"
                                helperText={resourceFinal[appLanguage].admin.SIOM_STG_MOMENT_DEFAULT_DURATION_HTEXT}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_RESERVATION}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.isReservation} onChange={(e) => setNewReservation(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_RESERVATION_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_RESERVATION_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_DEPARTMENTS}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.useMultipleDepartments} onChange={(e) => setNewDepartments(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_DEPARTMENTS_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_DEPARTMENTS_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_SHOW_ALL_DEPARTMENTS}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.employeeShowAllDepartments} onChange={(e) => setNewEmployeeShowAllDepartments(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_SHOW_ALL_DEPARTMENTS_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_SHOW_ALL_DEPARTMENTS_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_SHOW_REGISTER_TAB}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={siomSettings?.showSearchRegisterTab} onChange={(e) => setNewShowSearchRegisterTab(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.SIOM_STG_SHOW_REGISTER_TAB_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_STG_SHOW_REGISTER_TAB_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_TRAVEL_COST}</FormLabel>
                            <TextField
                                fullWidth type="number"
                                defaultValue={siomSettings?.travelCostHoursLimit}
                                inputProps={{ min: 1 }}
                                onChange={(e) => setNewTravelCosts(e.target.value)}
                                variant="standard"
                                helperText={resourceFinal[appLanguage].admin.SIOM_STG_TRAVEL_COST_HTEXT}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_STG_UNSUBSCRIBE_PERIOD}</FormLabel>
                            <TextField
                                fullWidth type="number"
                                defaultValue={siomSettings?.unsubscribePeriod}
                                inputProps={{ min: 0 }}
                                onChange={(e) => setNewUnsubscribePeriod(e.target.value)}
                                variant="standard"
                                helperText={resourceFinal[appLanguage].admin.SIOM_STG_UNSUBSCRIBE_PERIOD_HTEXT}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        } />
    );
}
