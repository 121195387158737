import { Box, Grid, Modal } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GetDate } from '../../app/AppUtils';
import ListItemCard from '../subcomponents/ListItemCard';
import StudentInfoEditCard from './StudentInfoEditCard';

import CakeIcon from '@mui/icons-material/Cake';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import Man4Icon from '@mui/icons-material/Man4';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 400 },
    boxShadow: 24,
};

export default function StudentInfoContentV1({ content, editable }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [open, setOpen] = useState(false);
    const [property, setProperty] = useState();

    const handleOpen = (_property) => {
        setOpen(true);
        setProperty(_property);
    };

    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.number}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_STUDENTNUMBER}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PersonIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={`${content?.firstName} ${content?.middleName} ${content?.lastName}`}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_NAME}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<GroupsIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.class ? content?.extraInfo?.class : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CLASS}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<Man4Icon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.mentor ? content?.extraInfo?.mentor : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_MENTOR}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<CakeIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={GetDate(content?.dateOfBirth)}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<EmailIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.workEmail}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_SCHOOL}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<PhoneIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.privatePhone ? content?.privatePhone : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                            action={() => handleOpen('privatePhone')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<PhoneIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.privatePhone ? content?.privatePhone : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {editable ? (
                        <ListItemCard
                            avatarIcon={<EmailIcon />}
                            avatarColor={'secondary.main'}
                            altBgColor={{ bgcolor: 'white.main' }}
                            primaryText={content?.privateEmail ? content?.privateEmail : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE}
                            action={() => handleOpen('privateEmail')}
                            actionIcon={<EditIcon color="secondary" />}
                        />
                    ) : (
                        <ListItemCard
                            avatarIcon={<EmailIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.privateEmail ? content?.privateEmail : "-"}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE}
                        />
                    )}
                </Grid>
            </Grid>

            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <StudentInfoEditCard studentInfo={content} property={property} />
                </Box>
            </Modal>
        </>
    );
}
