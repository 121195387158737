import InfoIcon from '@mui/icons-material/Info';
import { Card, CardActions, CardContent, CardMedia, Checkbox, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardSmall } from '../../app/AppStyles';
import { setScrollPosition } from '../activity/activitySlice';
import { resetRegisterStatusses } from '../register/registerSlice';
import { addFavorite, removeFavorite } from './favoritesSlice';

import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';

export default function FavoriteCard({ favoritePage, itemId, activityId, name, imageUrl, userFavorite }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { favorites } = useSelector((state) => state.favorites);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const HeartClick = () => {
        if (userFavorite || favoritePage) {
            if (favoritePage) {
                if (siomSettings.useMultipleDepartments) {
                    if (activeDepartment !== null) {
                        dispatch(removeFavorite({ activityId: itemId, departmentId: activeDepartment[0].id }));
                    };
                } else {
                    dispatch(removeFavorite({ activityId: itemId, departmentId: null }));
                };

                dispatch(resetRegisterStatusses());
                dispatch(setScrollPosition(window.pageYOffset));
            } else {
                var result = favorites.filter(obj => {
                    return obj.activityId === activityId;
                });

                if (siomSettings.useMultipleDepartments) {
                    if (activeDepartment !== null) {
                        dispatch(removeFavorite({ activityId: result[0].id, departmentId: activeDepartment[0].id }));
                    };
                } else {
                    dispatch(removeFavorite({ activityId: result[0].id, departmentId: null }));
                };

                dispatch(resetRegisterStatusses());
                dispatch(setScrollPosition(window.pageYOffset));
            };
        } else {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(addFavorite({ activityId: activityId, departmentId: activeDepartment[0].id }));
                };
            } else {
                dispatch(addFavorite({ activityId: activityId, departmentId: null }));
            };
            dispatch(resetRegisterStatusses());
            dispatch(setScrollPosition(window.pageYOffset));
        };
    };

    const InfoClick = (name, activityId) => {
        navigate(`/activiteit/${encodeURIComponent(name)}/${activityId}`);
    };

    return (
        <Card sx={{ ...CardSmall }}>
            <CardMedia component="img" sx={{
                height: { xs: 80, md: 140 }
            }} image={imageUrl ? imageUrl : '/imgs/Placeholder.png'} alt="activity" />
            <CardContent sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', p: 0.5 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {name}
                </Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'center', p: 0 }}>
                <IconButton size="small" onClick={() => InfoClick(name, activityId)}>
                    <InfoIcon color="secondary" />
                </IconButton>
                <Checkbox
                    icon={<FavoriteBorder color="secondary" />}
                    checkedIcon={<Favorite color="secondary" />}
                    checked={(favoritePage || userFavorite)}
                    onClick={() => HeartClick()}
                />
            </CardActions>
        </Card>
    );
}
