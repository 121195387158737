import AddIcon from '@mui/icons-material/Add';
import { Avatar, Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import FavoriteCard from './FavoriteCard';
import FavoritesCheckMessage from './FavoritesCheckMessage';
import { fetchFavorites } from './favoritesSlice';

export default function FavoritesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { student } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { favorites, favoritesStatus, removeStatus } = useSelector((state) => state.favorites);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    useEffect(() => {
        if (student !== null) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchFavorites(activeDepartment[0].id));
                };
            } else {
                dispatch(fetchFavorites(null));
            };
        };
    }, [dispatch, siomSettings, student, removeStatus, activeDepartment, departmentThemesStatus]);

    const AddClick = () => {
        navigate(`/favorieten-toevoegen`);
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.FAVORITES} content={
            <ContentLoader successCondition={favoritesStatus === "success"} errorCondition={favoritesStatus === "failed"} content={
                <>
                    <FavoritesCheckMessage />
                    <Grid container spacing={{ xs: 1, md: 2 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {favoritesStatus === "success" ? favorites?.map((item) =>
                            <Grid item key={item.id}>
                                <FavoriteCard favoritePage={true} itemId={item.id} activityId={item.activityId} name={item.name} imageUrl={item.imageUrl} />
                            </Grid>
                        ) : ""}
                        {favoritesStatus === "success" ? Object.keys(favorites).length === 0 ? (
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography variant="h6" sx={{ my: 'auto', textAlign: 'center' }}>{resourceFinal[appLanguage].messages.NO_FAVORITES}</Typography>
                            </Grid>
                        ) : "" : ""}
                    </Grid>

                </>
            } />
        } bottomBar contentBottom={
            <IconButton onClick={AddClick}>
                <AddIcon sx={{ color: 'white.main', fontSize: 32 }} />
            </IconButton>
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <Tooltip title={resourceFinal[appLanguage].words.ADD} placement="left" arrow>
                    <IconButton sx={{ alignSelf: 'flex-end' }} onClick={AddClick}>
                        <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                            <AddIcon sx={{ color: 'white.main', fontSize: 48 }} />
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
        } />
    );
}
