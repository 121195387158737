import { alpha, Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../app/AppStyles';
import TablePaginationActions from '../subcomponents/TablePaginationActions';
import { fetchMessageStudentsClass } from './messageSlice';

export default function MessagesClasses({ selectedClasses, setSelectedClasses }) {
    const dispatch = useDispatch();

    const { msgClasses, postMessageStatus } = useSelector((state) => state.messages);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (postMessageStatus === "success") {
            setSelectedClasses([]);
        };
    }, [postMessageStatus]);

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVE}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    function EnhancedTableToolbar({ numSelected }) {
        const handleFillClick = () => {
            dispatch(fetchMessageStudentsClass(selectedClasses.join()));
        };

        return (
            <Toolbar disableGutters sx={{ px: 1, display: 'flex', justifyContent: 'space-between', ...(numSelected > 0 && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }) }}>
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}
                    </Typography>
                ) : (
                    <Typography
                        sx={{ ...PageHeader, textAlign: 'left' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {resourceFinal[appLanguage].words.CLASSES}
                    </Typography>
                )}

                {numSelected > 0 && <Button sx={{ width: 180 }} variant="outlined" color="secondary" onClick={handleFillClick}>{resourceFinal[appLanguage].words.FILL} {resourceFinal[appLanguage].words.STUDENTS.toLowerCase()}</Button>}
            </Toolbar>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = msgClasses.map((n) => n.id);
            setSelectedClasses(newSelected);
            return;
        }
        setSelectedClasses([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedClasses.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedClasses, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedClasses.slice(1));
        } else if (selectedIndex === selectedClasses.length - 1) {
            newSelected = newSelected.concat(selectedClasses.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedClasses.slice(0, selectedIndex),
                selectedClasses.slice(selectedIndex + 1),
            );
        };

        setSelectedClasses(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - msgClasses.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selectedClasses.findIndex(x => x === id) !== -1;
    return (
        <Box sx={{ p: 0.5, ...ListItemBackground }}>
            <EnhancedTableToolbar numSelected={selectedClasses.length} />
            <TableContainer>
                <Table>
                    <EnhancedTableHead numSelected={selectedClasses.length} onSelectAllClick={handleSelectAllClick} rowCount={msgClasses?.length} />
                    <TableBody>
                        {(rowsPerPage > 0 ? msgClasses?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : msgClasses)?.map((item, index) => {
                            const isItemSelected = isSelected(item.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover onClick={(event) => handleClick(event, item.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.id} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                    <TableCell padding="checkbox">
                                        <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                    </TableCell>
                                    <TableCell align="left">{item.naam}</TableCell>
                                    <TableCell align="center">{item.isActive ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}</TableCell>
                                </TableRow>
                            );
                        })}
                        {(rowsPerPage > 0 ? msgClasses?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : msgClasses)?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                        {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                sx={{ border: 0 }}
                                rowsPerPageOptions={[10, 25, 50]}
                                count={msgClasses ? msgClasses.length : 0}
                                rowsPerPage={rowsPerPage} page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}
