import { Box, Checkbox, FormControlLabel, FormLabel, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudentSettings } from '../settings/studentSettingsSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { putSetting, resetUpdateStatus } from './adminSlice';

export default function StudentSettingsTab() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { studentSettings, studentSettingsStatus } = useSelector((state) => state.settings.student);
    const { updateSettingStatus } = useSelector((state) => state.admin);

    // Fetch student settings
    useEffect(() => {
        dispatch(fetchStudentSettings());
    }, [dispatch]);

    // Put Callback
    useEffect(() => {
        if (updateSettingStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchStudentSettings());
        } else if (updateSettingStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateSettingStatus]);

    // Private email required setting
    const [newPrivateMailReq, setNewPrivateMailReq] = useState(null);
    useEffect(() => {
        if (newPrivateMailReq !== null) {
            dispatch(putSetting({
                key: "Student_EmailVerplicht",
                value: newPrivateMailReq ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newPrivateMailReq]);

    // Private phone required setting
    const [newPrivatePhoneReq, setNewPrivatePhoneReq] = useState(null);
    useEffect(() => {
        if (newPrivatePhoneReq !== null) {
            dispatch(putSetting({
                key: "Student_PriveTelefoonVerplicht",
                value: newPrivatePhoneReq ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newPrivatePhoneReq]);

    // Work phone required setting
    const [newWorkPhoneReq, setNewWorkPhoneReq] = useState(null);
    useEffect(() => {
        if (newWorkPhoneReq !== null) {
            dispatch(putSetting({
                key: "Student_WerkTelefoonVerplicht",
                value: newWorkPhoneReq ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newWorkPhoneReq]);

    // Iban required setting
    const [newIbanReq, setNewIbanReq] = useState(null);
    useEffect(() => {
        if (newIbanReq !== null) {
            dispatch(putSetting({
                key: "Student_IbanVerplicht",
                value: newIbanReq ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newIbanReq]);

    // Pok required setting
    const [newPokReq, setNewPokReq] = useState(null);
    useEffect(() => {
        if (newPokReq !== null) {
            dispatch(putSetting({
                key: "Student_PokVerplicht",
                value: newPokReq ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newPokReq]);

    return (
        <ContentLoader successCondition={studentSettingsStatus === "success"} errorCondition={studentSettingsStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.STUDENT_STG_EMAIL}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={studentSettings?.privateMailRequired} onChange={(e) => setNewPrivateMailReq(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.STUDENT_STG_EMAIL_HTEXT} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.STUDENT_STG_PHONE_PRIVATE}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={studentSettings?.privatePhoneRequired} onChange={(e) => setNewPrivatePhoneReq(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.STUDENT_STG_PHONE_PRIVATE_HTEXT} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.STUDENT_STG_PHONE_WORK}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={studentSettings?.workPhoneRequired} onChange={(e) => setNewWorkPhoneReq(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.STUDENT_STG_PHONE_WORK_HTEXT} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.STUDENT_STG_IBAN}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={studentSettings?.ibanRequired} onChange={(e) => setNewIbanReq(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.STUDENT_STG_IBAN_HTEXT} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.STUDENT_STG_POK}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={studentSettings?.pokRequired} onChange={(e) => setNewPokReq(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.STUDENT_STG_POK_HTEXT} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        } />
    );
}
