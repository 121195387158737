import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';

export default function LocationButton({ locationString }) {

    const locationClick = () => {
        window.open(`https://www.google.nl/maps/dir//+${locationString}`, '_blank');
    };

    return (
        <IconButton onClick={locationClick}>
            <LocationOnIcon color="secondary" />
        </IconButton>
    )
}