import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import RegisterCard from '../../register/RegisterCard';
import ContentLoader from '../../subcomponents/ContentLoader';
import NoContentDisplay from '../../subcomponents/NoContentDisplay';
import { setStudentRegisterPage } from '../searchSlice';

export default function SearchStudentRegisterTab() {
    const dispatch = useDispatch();
    const { register, registerAll, registerStatus, registerAllStatus, periods, periodsStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchStudent, searchStudentStatus, studentRegisterReasonMode, studentRegisterFilter } = useSelector((state) => state.search);

    const [registerContent, setRegisterContent] = useState()
    const [registerContentStatus, setRegisterContentStatus] = useState()

    useEffect(() => {
        dispatch(setStudentRegisterPage("REGISTER"));

        if (studentRegisterReasonMode) {
            setRegisterContent(registerAll);
            setRegisterContentStatus(registerAllStatus);
        } else {
            setRegisterContent(register);
            setRegisterContentStatus(registerStatus);
        }
    }, [studentRegisterReasonMode]);

    var customFilterFunction = (item) => {
        return item.activityName.toLowerCase().includes(studentRegisterFilter.toLowerCase()) &&
            periods?.filter(e1 => item.activityPerformances.some(e2 => e1.periodId === e2.periode.periodId)).some((e) => e.checked);
    };

    return (
        <>
            <ContentLoader successCondition={searchStudentStatus === "success" && periodsStatus === "success"} errorCondition={searchStudentStatus === "failed" || periodsStatus === "failed"} customError={
                <>
                    {(periodsStatus === "failed" || searchStudentStatus === "failed") &&
                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                            <Typography variant="h5">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_REGISTER}</Typography>
                        </Box>}
                </>
            } content={
                <>
                    {!studentRegisterReasonMode && (searchStudent?.studentProgress?.allPointLimitsReached) ?
                        <Typography sx={{ width: 1, textAlign: 'center' }} variant="h6">{resourceFinal[appLanguage].messages.REGISTER_LIMIT}</Typography>
                        :
                        <ContentLoader successCondition={registerContentStatus === "success"} errorCondition={registerContentStatus === "failed"} customError={
                            <>
                                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                                    <Typography variant="h5">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_REGISTER}</Typography>
                                </Box>
                            </>
                        } content={
                            <Box>
                                <Grid container spacing={{ xs: 1, md: 2 }} justifyContent="center" alignItems="flex-start">
                                    {registerContent?.filter(customFilterFunction)?.map((item) =>
                                        <Grid item key={item.performanceId}>
                                            <RegisterCard performance={item} employeeMode reasonMode={studentRegisterReasonMode} />
                                        </Grid>
                                    )}

                                    {registerContent?.filter(customFilterFunction)?.length <= 0 ?
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <NoContentDisplay message={resourceFinal[appLanguage].messages.NO_REGISTER_CLINICS} />
                                        </Grid> : ""}
                                </Grid>
                            </Box>
                        } />
                    }
                </>
            } />
        </>
    );
}
