import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import StudentInfoContentV1 from '../../student/StudentInfoContentV1';
import StudentInfoContentV2 from '../../student/StudentInfoContentV2';
import VersionSwitcher from '../../subcomponents/VersionSwitcher';

export default function SearchStudentInfoTab() {
    const { searchStudent } = useSelector((state) => state.search);

    return (
        <Box>
            <VersionSwitcher content1={<StudentInfoContentV1 content={searchStudent?.studentData} />} content2={<StudentInfoContentV2 content={searchStudent?.studentData} />} />
        </Box>
    );
}
