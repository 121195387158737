import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Fetches
import { fetchAppSettings, getUser } from '../components/settings/appSettingsSlice';
import { fetchNavSettings, getResources } from '../components/settings/resourceSettingsSlice';
import { fetchSiomSettings } from '../components/settings/siomSettingsSlice';
import { fetchDefaultThemeSettings, fetchDepartmentThemeSettings } from '../components/settings/themeSettingsSlice';
import { checkUser, fetchUserData } from '../components/user/userSlice';

// Components
import authService from '../components/api-authorization/AuthorizeService';
import NavMenu from '../components/misc/NavMenu';
import AlertDisplay from '../components/subcomponents/AlertDisplay';
import AppChecks from './AppChecks';
import AppRouting from './AppRouting';

export default function App() {
    const dispatch = useDispatch();
    const [themeLoaded, setThemeLoaded] = useState(false);
    const [currentTheme, setCurrentTheme] = useState();
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings, siomSettingsStatus } = useSelector((state) => state.settings.siom);
    const { defaultThemeStatus, departmentThemesStatus, activeThemeSettings } = useSelector((state) => state.settings.theme);

    // Fetch user
    useEffect(() => {
        const fetchUser = async () => {
            await Promise.all([authService.isAuthenticated(), authService.getUser()])
                .then(res => {
                    if (res[0])
                        dispatch(getUser(res[1]));
                });
        };

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch default theme, resources and siom settings
    useEffect(() => {
        dispatch(fetchDefaultThemeSettings());
        dispatch(getResources());
        dispatch(fetchAppSettings())
        dispatch(fetchSiomSettings());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Fetch and check student, fetch navigation items
    useEffect(() => {
        if (aspNetUser !== null) {
            dispatch(fetchNavSettings());
            dispatch(fetchUserData());
            dispatch(checkUser());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aspNetUser]);

    // Fetch department (theme)
    useEffect(() => {
        if (aspNetUser !== null && siomSettingsStatus === 'success') {
            if (siomSettings.useMultipleDepartments)
                dispatch(fetchDepartmentThemeSettings());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aspNetUser, siomSettingsStatus]);

    // Set theme (first default, then department if it exists)
    useEffect(() => {
        if (defaultThemeStatus === "success" || departmentThemesStatus === "success") {
            if (activeThemeSettings !== null) {

                setCurrentTheme(createTheme({
                    palette: {
                        primary: {
                            main: activeThemeSettings.primaryColor,
                        },
                        secondary: {
                            main: activeThemeSettings.secondaryColor,
                        },
                        white: {
                            dark: "#babdbe",
                            main: "#eceff1",
                            light: "#ffffff"
                        }
                    },
                }));
            }

            setThemeLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultThemeStatus, departmentThemesStatus]);

    return (
        <>
            {themeLoaded && siomSettingsStatus === 'success' && resourceFinal ?
                <ThemeProvider theme={currentTheme}>
                    <AppChecks />
                    <NavMenu />
                    <AppRouting />
                    <AlertDisplay />
                </ThemeProvider>
                : ``}
        </>
    )
}
