import { Box, Card, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardHeader, CardMedium, MainBorderRadius } from '../../app/AppStyles';
import FlagDisplay from '../flags/FlagDisplay';
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import ProgressBar from './ProgressBar';
import { fetchProgressOverview } from './progressSlice';

export default function ProgressOverviewPage() {
    const dispatch = useDispatch();
    const { progressOverview, progressOverviewStatus } = useSelector((state) => state.progress);
    const { activeDepartment, departmentThemes, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user } = useSelector((state) => state.user);
    const { deleteStatus } = useSelector((state) => state.register);
    const desktop = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        if (user !== null && activeDepartment !== null) {
            var departmentIds = departmentThemes.map(function (item) { return item.id })
            dispatch(fetchProgressOverview({ studentId: user.studentId, departmentIds: departmentIds, fullProgress: false }))
        }
    }, [dispatch, user, activeDepartment, deleteStatus, departmentThemesStatus, departmentThemes]);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.PROGRESS} titleLower={resourceFinal[appLanguage].words.OVERVIEW} content={
            <ContentLoader successCondition={progressOverviewStatus === "success"} errorCondition={progressOverviewStatus === "failed"} content={
                <>
                    <Grid container spacing={1}>
                        {progressOverview?.map(item =>
                            <Grid item xs={12} key={item.programPoints[0].programId}>
                                <Card sx={{ ...CardMedium }}>
                                    <Typography variant="h6" sx={{ ...CardHeader, color: departmentThemes.filter(x => x.id === item.progressProgram.departmentId)[0].themeSettings.primaryColor }}>{item.progressProgram.programName}</Typography>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item xs={12} md={8}>
                                            <Box sx={{ display: 'flex', flex: '1 1 auto', alignContent: 'center', bgcolor: 'white.main', borderRadius: MainBorderRadius, p: 1 }}>
                                                <ProgressBar progressPoints={item.totalPoints} legendDisplay={false} barHeight={desktop ? 120 : 100} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                                            <Box sx={{ display: 'flex', flex: '1 1 auto', alignContent: 'center', bgcolor: 'white.main', borderRadius: MainBorderRadius, p: 1 }}>
                                                <FlagDisplay progressFlags={item.progressFlags} xsIconSize={40} mdIconSize={60} compact={true} sizeOverride={true} departmentOverride={true} departmentSettings={departmentThemes.filter(x => x.id === item.progressProgram.departmentId)[0].themeSettings} departmentId={item.progressProgram.departmentId} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}
