import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { GetDate, GetTime } from '../../app/AppUtils';
import GridCard from '../subcomponents/GridCard';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ProgressRemarks({ remarks }) {

    const RemarkCard = ({ item }) => {
        const [open, setOpen] = useState(false);

        return (
            <GridCard
                actionClick={() => setOpen(!open)}
                actionIcon={open ? <KeyboardArrowUpIcon color="secondary" /> : <KeyboardArrowDownIcon color="secondary" />}
                textPrimary={item.authorName}
                textSecondary={!!item.dateModified ?
                    `*${GetDate(item.dateModified)} (${GetTime(item.dateModified)})` :
                    `${GetDate(item.dateCreated)} (${GetTime(item.dateCreated)})`}
                textTertiary={open && <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.remarkText.replace(/\\n/g, '<br />')) }} />}
                textTertiaryStyle={{}}
                wrapEnabled
            />
        );
    }

    return (
        <Grid container spacing={0.5}>
            {remarks.map((item, index) =>
                <Grid xs={12} key={index}>
                    <RemarkCard item={item} />
                </Grid>
            )}
        </Grid>
    );
}