import { Box, Button, ButtonGroup, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground } from '../../app/AppStyles';
import { DisplayName } from '../../app/AppUtils';
import ContentLoader from '../subcomponents/ContentLoader';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import MessagesClasses from './MessagesClasses';
import MessagesPerformances from './MessagesPerformances';
import MessagesStudents from './MessagesStudents';
import { fetchMessageActivities, fetchMessageClasses, fetchMessageStudents, postMessage, resetMessageStatus, setMsgStudentFilter } from './messageSlice';

export default function MessageSendTab() {
    const dispatch = useDispatch();
    const radioRef = useRef();

    const [messageSubject, setMessageSubject] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [messageMode, setMessageMode] = useState('Class');
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [selectedPerformances, setSelectedPerformances] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);

    const { msgClassesStatus, msgActivities, msgActivitiesStatus, msgReceivers, postMessageStatus } = useSelector((state) => state.messages);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchMessageClasses());
    }, [dispatch]);

    const resetClick = () => {
        dispatch(resetMessageStatus());
        setSelectedClasses([]);
        setSelectedStudents([]);
        setSelectedActivity('');
        setMessageSubject("");
        setMessageBody("");
        dispatch(setMsgStudentFilter(""));

        if (messageMode === "Student")
            dispatch(fetchMessageStudents());
    };

    const handleRadioClick = (value) => {
        dispatch(resetMessageStatus());

        if (value === "Class")
            dispatch(fetchMessageClasses());

        if (value === "Performance")
            dispatch(fetchMessageActivities());

        if (value === "Student")
            dispatch(fetchMessageStudents());

        dispatch(setMsgStudentFilter(""));
        setSelectedClasses([]);
        setSelectedPerformances([]);
        setSelectedStudents([]);
        setSelectedActivity('');
        setMessageMode(value);
    };

    const handleSendClick = () => {
        if (!msgReceivers) return
        if (!messageSubject) return;
        if (!messageBody) return;

        var studentNumbers = msgReceivers.map(function (item) {
            return item['studentNumber'];
        });

        var studentIds = msgReceivers.map(function (item) {
            return item['studentId'];
        });

        var messageObject = {
            title: messageSubject,
            body: messageBody,
            subject: messageSubject,
            sentFrom: `${user.number}`,
            displayName: `${DisplayName(user.firstName, user.middleName, user.lastName)}`,
            direction: 'Sent',
            type: 'App',
            studentNummer: studentNumbers,
            studentIds: studentIds,
            notificationMessage: `${resourceFinal[appLanguage].messages.NOTIFICATION_NEW_MESSAGE}`,
        };

        dispatch(postMessage(messageObject));
    };

    // post & put status callback
    useEffect(() => {
        if (postMessageStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.SENT_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetMessageStatus());

            setSelectedClasses([]);
            setSelectedStudents([]);
            setMessageSubject("");
            setMessageBody("");
            dispatch(setMsgStudentFilter(""));

            if (messageMode === "Student")
                dispatch(fetchMessageStudents());
        } else if (postMessageStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.SENT_ERROR}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetMessageStatus());
        };
    }, [postMessageStatus]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} ref={radioRef}>
                <Box sx={{ p: 1, ...ListItemBackground }}>
                    <FormControl onClick={(e) => handleRadioClick(e.target.value)}>
                        <FormLabel>{resourceFinal[appLanguage].words.SELECT} {resourceFinal[appLanguage].words.MODE.toLowerCase()}</FormLabel>
                        <RadioGroup defaultValue="Class">
                            <FormControlLabel value="Class" control={<Radio />} label={resourceFinal[appLanguage].words.CLASSES} />
                            <FormControlLabel value="Performance" control={<Radio />} label={resourceFinal[appLanguage].words.PERFORMANCES} />
                            <FormControlLabel value="Student" control={<Radio />} label={resourceFinal[appLanguage].words.STUDENTS} />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            {messageMode === "Performance" && <Grid item xs={12}>
                <Box sx={{ p: 1, ...ListItemBackground }}>
                    <FormControl fullWidth>
                        <InputLabel>{resourceFinal[appLanguage].words.ACTIVITIES}</InputLabel>
                        <Select label={resourceFinal[appLanguage].words.ACTIVITIES} value={selectedActivity} onChange={(e) => setSelectedActivity(e.target.value)}>
                            {msgActivities?.map((item) =>
                                <MenuItem key={item.id} value={item}>{item.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>}
            {(messageMode === "Class" || messageMode === "Performance") && <Grid item xs={12}>
                {messageMode === "Class" &&
                    <ContentLoader successCondition={msgClassesStatus === "success"} errorCondition={msgClassesStatus === "failed"} content={
                        <MessagesClasses selectedClasses={selectedClasses} setSelectedClasses={setSelectedClasses} />
                    } />}
                {messageMode === "Performance" &&
                    <ContentLoader successCondition={msgActivitiesStatus === "success"} errorCondition={msgActivitiesStatus === "failed"} content={
                        <MessagesPerformances selectedActivityPerformances={selectedActivity?.performances} selectedPerformances={selectedPerformances} setSelectedPerformances={setSelectedPerformances} />
                    } />}
            </Grid>}
            <Grid item xs={12}>
                <MessagesStudents selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{msgReceivers?.length > 1 ? resourceFinal[appLanguage].words.RECEIVERS : resourceFinal[appLanguage].words.RECEIVER}: {msgReceivers?.map(n => n.studentNumber).join(', ')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={{ ...ListItemBackground }}
                    fullWidth
                    value={messageSubject}
                    label={resourceFinal[appLanguage].words.SUBJECT}
                    onChange={(event) => setMessageSubject(event.target.value)}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={{ ...ListItemBackground }}
                    fullWidth multiline
                    value={messageBody}
                    label={resourceFinal[appLanguage].words.BODY}
                    onChange={(event) => setMessageBody(event.target.value)}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonGroup variant="outlined">
                    <Button disabled={!msgReceivers && !messageSubject && !messageBody} color="error" onClick={resetClick}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                    <Button disabled={!msgReceivers || !messageSubject || !messageBody} onClick={handleSendClick}>{resourceFinal[appLanguage].words.SEND} {resourceFinal[appLanguage].words.MESSAGE}</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}
