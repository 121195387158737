import { Box, Button, ButtonGroup, Card, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { getSubprogramActivities, putSubprogramActivity, setFailedSubprogramActivity } from './subprogramManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};
export default function SubprogramActivityEditCard({ handleClose }) {
    const dispatch = useDispatch();

    const [emptyError, setEmptyError] = useState(false);
    const [triedSubmit, setTriedSubmit] = useState(false);

    const { user } = useSelector((state) => state.user);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedSubprogram, putSubprogramActivitiesStatus, selectedSubprogramActivity, showSubprogramActivitiesFailed } = useSelector((state) => state.management.subprogram);

    // Put status callback
    useEffect(() => {
        if (putSubprogramActivitiesStatus === "success") {
            dispatch(setFailedSubprogramActivity(false));
            dispatch(getSubprogramActivities(selectedSubprogram.id));
            handleClose();
        } else if (putSubprogramActivitiesStatus === "failed") {
            dispatch(setFailedSubprogramActivity(true));
        };
    }, [dispatch, handleClose, selectedSubprogram, putSubprogramActivitiesStatus]);

    // Property states
    const [updatedSubprogramActivity, setUpdatedSubprogramActivity] = useState({});
    const [changed, setChanged] = useState(false);

    // Selected program state update 
    useEffect(() => {
        if (selectedSubprogramActivity)
            setUpdatedSubprogramActivity(selectedSubprogramActivity);
    }, [selectedSubprogramActivity]);

    // Keep track of changed state 
    useEffect(() => {
        if (updatedSubprogramActivity !== selectedSubprogramActivity) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedSubprogramActivity, updatedSubprogramActivity]);

    // Check properties are empty
    useEffect(() => {
        if (triedSubmit && (updatedSubprogramActivity.minSubscriptions === null || updatedSubprogramActivity.maxSubscriptions === null || updatedSubprogramActivity.maxAbsence === null))
            setEmptyError(true);
        else
            setEmptyError(false);
    }, [updatedSubprogramActivity, triedSubmit]);

    // Update click function
    const updateClick = () => {
        if (updatedSubprogramActivity.minSubscriptions === null || updatedSubprogramActivity.maxSubscriptions === null || updatedSubprogramActivity.maxAbsence === null) {
            setTriedSubmit(true);
            return;
        };

        updatedSubprogramActivity.ModifiedBy = `AP/${user.number}`;
        dispatch(putSubprogramActivity(updatedSubprogramActivity));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.LINKED} {resourceFinal[appLanguage].words.SUBPROGRAM.toLowerCase()} {resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth required
                                type="number"
                                inputProps={{ min: 0 }}
                                defaultValue={selectedSubprogramActivity?.minSubscriptions ?? 0}
                                error={updatedSubprogramActivity.minSubscriptions === null && triedSubmit}
                                label={resourceFinal[appLanguage].words.SUBSCRIPTIONS_MIN}
                                onChange={(event) => setUpdatedSubprogramActivity({
                                    ...updatedSubprogramActivity,
                                    minSubscriptions: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth required
                                type="number"
                                inputProps={{ min: 0 }}
                                defaultValue={selectedSubprogramActivity?.maxSubscriptions ?? 0}
                                error={updatedSubprogramActivity.maxSubscriptions === null && triedSubmit}
                                label={resourceFinal[appLanguage].words.SUBSCRIPTIONS_MAX}
                                onChange={(event) => setUpdatedSubprogramActivity({
                                    ...updatedSubprogramActivity,
                                    maxSubscriptions: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={siomSettings?.flagProgress ? 6 : 12}>
                            <TextField
                                fullWidth required
                                type="number"
                                inputProps={{ min: 0 }}
                                defaultValue={selectedSubprogramActivity?.maxAbsence ?? 0}
                                error={updatedSubprogramActivity.maxAbsence === null && triedSubmit}
                                label={resourceFinal[appLanguage].words.ABSENCE_MAX}
                                onChange={(event) => setUpdatedSubprogramActivity({
                                    ...updatedSubprogramActivity,
                                    maxAbsence: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {showSubprogramActivitiesFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                            {(emptyError && triedSubmit) && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_ANY_EMPTY}</Typography>}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button disabled={!changed || emptyError} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}