import { Backdrop, Button, Card, CardContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { putStudentInfo } from './studentSlice';

export default function StudentInfoWarning() {
    const dispatch = useDispatch();
    const location = useLocation();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { studentInfoSettings, studentInfoSettingsStatus, putStudentInfoStatus } = useSelector((state) => state.student);

    const [emailValue, setEmailValue] = useState(null);
    const [mobilePhoneValue, setMobilePhoneValue] = useState(null);
    const [anyPhoneValue, setAnyPhoneValue] = useState(null);
    const [ibanValue, setIbanValue] = useState(null);
    const [pokValue, setPokValue] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [mobilePhoneError, setMobilePhoneError] = useState(null);
    const [anyPhoneError, setAnyPhoneError] = useState(null);
    const [ibanError, setIbanError] = useState(null);
    const [pokError, setPokError] = useState(null);
    const [forcedInfo, setForcedInfo] = useState(true);

    const [saveFailed, setSaveFailed] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (emailError !== null && isValidEmail(emailValue)) {
            setEmailError(null);
        };

        if (mobilePhoneError !== null && isValidMobilePhone(mobilePhoneValue)) {
            setMobilePhoneError(null);
        };

        if (anyPhoneError !== null && isValidAnyPhone(anyPhoneValue)) {
            setAnyPhoneError(null);
        };

        if (ibanError !== null && isValidIban(ibanValue)) {
            setIbanError(null);
        };

        if (pokError !== null && (pokValue === "True" || pokValue === "False")) {
            setPokError(null);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailValue, mobilePhoneValue, anyPhoneValue, ibanValue, pokValue]);

    useEffect(() => {
        if (studentInfoSettings !== null)
            setForcedInfo(
                (!studentInfoSettings?.privateEmailRequired || studentInfoSettings?.privateEmail) &&
                (!studentInfoSettings?.privatePhoneRequired || studentInfoSettings?.privatePhone) &&
                (!studentInfoSettings?.workPhoneRequired || studentInfoSettings?.workPhone) &&
                (!studentInfoSettings?.ibanRequired || studentInfoSettings?.iban) &&
                (!studentInfoSettings?.pokRequired || studentInfoSettings?.pok !== "")
            );
    }, [studentInfoSettings]);

    useEffect(() => {
        if (putStudentInfoStatus === "success") {
            window.location.reload();
        } else if (putStudentInfoStatus === "failed") {
            setSaveFailed(true);
            setDisabled(false);
        };
    }, [dispatch, putStudentInfoStatus]);

    function isValidEmail(input) {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input);
    };

    function isValidMobilePhone(input) {
        return /^\(?(0)-?6(\s?|-)([0-9]\s{0,2}){8}$/.test(input);
    };

    function isValidAnyPhone(input) {
        return /^((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9]){8}$/.test(input);
    };

    function isValidIban(input) {
        return /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/.test(input);
    };

    function isValidPok(input) {
        return input !== null;
    };

    const handleEmailChange = event => setEmailValue(event.target.value);
    const handleMobilePhoneChange = event => setMobilePhoneValue(event.target.value);
    const handleAnyPhoneChange = event => setAnyPhoneValue(event.target.value);
    const handleIbanChange = event => setIbanValue(event.target.value);
    const handlePokChange = event => setPokValue(event.target.value);

    const submitValues = () => {
        if (studentInfoSettings.privateEmailRequired && !studentInfoSettings.privateEmail && !isValidEmail(emailValue)) {
            setEmailError(resourceFinal[appLanguage].messages.INVALID_EMAIL);
        };

        if (studentInfoSettings.privatePhoneRequired && !studentInfoSettings.privatePhone && !isValidMobilePhone(mobilePhoneValue)) {
            setMobilePhoneError(resourceFinal[appLanguage].messages.INVALID_MOBILE_PHONE);
        };

        if (studentInfoSettings.workPhoneRequired && !studentInfoSettings.workPhone && !isValidAnyPhone(anyPhoneValue)) {
            setAnyPhoneError(resourceFinal[appLanguage].messages.INVALID_PHONE);
        };

        if (studentInfoSettings.ibanRequired && !studentInfoSettings.iban && !isValidIban(ibanValue)) {
            setIbanError(resourceFinal[appLanguage].messages.INVALID_IBAN);
        };

        if (studentInfoSettings.pokRequired && pokValue === null) {
            setPokError(resourceFinal[appLanguage].messages.INVALID_POK);
        };

        if (isValidEmail(emailValue)) {
            setDisabled(true);
            dispatch(putStudentInfo({ key: 'Email', newValue: emailValue }));
        };

        if (isValidMobilePhone(mobilePhoneValue)) {
            setDisabled(true);
            dispatch(putStudentInfo({ key: 'Phone', secondaryKey: 'Private', newValue: mobilePhoneValue }));
        };

        if (isValidAnyPhone(anyPhoneValue)) {
            setDisabled(true);
            dispatch(putStudentInfo({ key: 'Phone', secondaryKey: 'Work', newValue: anyPhoneValue }));
        };

        if (isValidIban(ibanValue)) {
            setDisabled(true);
            dispatch(putStudentInfo({ key: 'IbanNumber', newValue: ibanValue }));
        };

        if (isValidPok(pokValue)) {
            setDisabled(true);
            dispatch(putStudentInfo({ key: 'PokTurnedIn', newValue: pokValue }));
        };
    };

    return (
        <>
            {location.pathname.toLocaleLowerCase() === "/account" ? "" :
                <>
                    {studentInfoSettingsStatus === 'success' &&
                        <>
                            {(studentInfoSettings.privateInfoRequired && !forcedInfo) &&
                                <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                                    <Card sx={{ height: 'auto', mx: 2, textAlign: 'center' }}>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="h6">{resourceFinal[appLanguage].messages.PRIVATE_INFO_WARNING}</Typography>
                                                {studentInfoSettings.privateEmailRequired && !studentInfoSettings.privateEmail &&
                                                    <TextField
                                                        fullWidth
                                                        error={emailError !== null}
                                                        helperText={emailError}
                                                        variant="outlined"
                                                        onChange={handleEmailChange}
                                                        label={resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE}
                                                    />}
                                                {studentInfoSettings.privatePhoneRequired && !studentInfoSettings.privatePhone &&
                                                    <TextField
                                                        fullWidth
                                                        error={mobilePhoneError !== null}
                                                        helperText={mobilePhoneError}
                                                        variant="outlined"
                                                        onChange={handleMobilePhoneChange}
                                                        label={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                                                    />}
                                                {studentInfoSettings.workPhoneRequired && !studentInfoSettings.workPhone &&
                                                    <TextField
                                                        fullWidth
                                                        error={anyPhoneError !== null}
                                                        helperText={anyPhoneError}
                                                        variant="outlined"
                                                        onChange={handleAnyPhoneChange}
                                                        label={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE}
                                                    />}
                                                {studentInfoSettings.ibanRequired && !studentInfoSettings.iban &&
                                                    <TextField
                                                        fullWidth
                                                        error={ibanError !== null}
                                                        helperText={ibanError}
                                                        variant="outlined"
                                                        onChange={handleIbanChange}
                                                        label={resourceFinal[appLanguage].words.USER_DATA_IBAN}
                                                    />}
                                                {studentInfoSettings.pokRequired && studentInfoSettings.pok === "" &&
                                                    <FormControl error={pokError !== null}>
                                                        <FormLabel>{resourceFinal[appLanguage].words.USER_DATA_POK}</FormLabel>
                                                        <RadioGroup onChange={handlePokChange}>
                                                            <FormControlLabel value="True" control={<Radio />} label={resourceFinal[appLanguage].words.YES} />
                                                            <FormControlLabel value="False" control={<Radio />} label={resourceFinal[appLanguage].words.NO} />
                                                        </RadioGroup>
                                                        <FormHelperText>{pokError}</FormHelperText>
                                                    </FormControl>}

                                                {saveFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                                                <Button disabled={disabled} sx={{ width: 80 }} onClick={() => submitValues()} variant="outlined" color="secondary">{resourceFinal[appLanguage].words.SAVE}</Button>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Backdrop>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}