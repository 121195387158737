import CreateIcon from '@mui/icons-material/Create';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Badge, Divider, IconButton, Menu, MenuList } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import authService from '../api-authorization/AuthorizeService';
import DepartmentPicker from '../department/DepartmentPicker';
import { fetchNotificationsCount } from '../notifications/notificationSlice';
import NavMenuItem from '../subcomponents/NavMenuItem';

export default function LoginMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { notificationsCount } = useSelector((state) => state.notifications);
    const { updateStatus } = useSelector((state) => state.notifications);
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { user } = useSelector((state) => state.user);

    const [localUser, setLocalUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [registerPath, setRegisterPath] = useState("");
    const [loginPath, setLoginPath] = useState("");
    const [logoutPath, setLogoutPath] = useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const clickMenu = (page) => {
        navigate(page);
        setAnchorEl(null);
    }

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();
    }, []);

    const populateState = async () => {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        setIsAuthenticated(isAuthenticated);
        setLocalUser(user);
    };

    useEffect(() => {
        if (!isAuthenticated) {
            setRegisterPath(`${ApplicationPaths.Register}`);
            setLoginPath(`${ApplicationPaths.Login}`);
        } else {
            setLogoutPath({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
        };
    }, [isAuthenticated]);

    useEffect(() => {
        if (aspNetUser !== null) {
            dispatch(fetchNotificationsCount());
        };
    }, [dispatch, aspNetUser, updateStatus]);

    function AvatarButton({ style }) {
        return (
            <>
                {isAuthenticated ? <Avatar sx={{ ...style, bgcolor: 'primary.dark' }}>{user?.firstName?.split("")[0].toUpperCase()}{user?.lastName?.split("")[0].toUpperCase()}</Avatar> : <Avatar sx={{ ...style, bgcolor: 'primary.light' }} />}
            </>
        )
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                {siomSettings.messagesEnabled ? <Badge sx={{ top: 5 }} color="secondary" badgeContent={notificationsCount}><AvatarButton style={{ top: -5 }} /></Badge> : <AvatarButton />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
            >
                {isAuthenticated ?
                    <MenuList>
                        <NavMenuItem icon={<ManageAccountsIcon color="primary" />} clickFn={() => clickMenu("/account")} text={resourceFinal[appLanguage].words.ACCOUNT} textStyle={{ color: 'primary.light' }} />
                        {siomSettings.messagesEnabled ? <NavMenuItem icon={<Badge color="secondary" badgeContent={notificationsCount}><NotificationsIcon color="primary" /></Badge>} clickFn={() => clickMenu("/notificaties")} text={resourceFinal[appLanguage].words.MESSAGES} textStyle={{ color: 'primary.light' }} /> : ""}
                        {siomSettings.useMultipleDepartments &&
                            <MenuList>
                                <DepartmentPicker type={"menu"} />
                            </MenuList>}
                        <Divider />
                        <NavMenuItem icon={<LogoutIcon color="primary" />} clickFn={() => {clickMenu(logoutPath)}} text={resourceFinal[appLanguage].words.LOGOUT} textStyle={{ color: 'primary.light' }} />
                    </MenuList>
                    :
                    <MenuList>
                        <NavMenuItem icon={<LoginIcon color="primary" />} clickFn={() => clickMenu(loginPath)} text={resourceFinal[appLanguage].words.LOGIN} textStyle={{ color: 'primary.light' }} />
                        {window.Config.enableRegister && <NavMenuItem icon={<CreateIcon color="primary" />} clickFn={() => clickMenu(registerPath)} text={resourceFinal[appLanguage].words.REGISTER_ACCOUNT} textStyle={{ color: 'primary.light' }} />}
                    </MenuList>
                }
            </Menu>
        </>
    )
}
