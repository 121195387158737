import { Avatar, Box, Card, CardActionArea, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { MainBorderRadius, SubcardBgcolor } from '../../app/AppStyles';

export default function GridCard({
    actionClick, actionIcon,
    avatarIcon, avatarBgcolor,
    cardBgcolor, cardHeight,
    textPrimary, textPrimaryStyle,
    textSecondary, textSecondaryStyle,
    textTertiary, textTertiaryStyle,
    textQuaternary, textQuaternaryStyle,
    tooltipEnabled, wrapEnabled
}) {
    const defaultPrimaryStyle = { fontWeight: 'bold', fontSize: 16 }
    const defaultSecondaryStyle = { fontWeight: 'light', fontSize: 14, color: 'gray' }
    const defaultTertiaryStyle = { fontWeight: 'bold', fontSize: 14, color: 'error.main' }
    const defaultQuaternaryStyle = { fontSize: 14 }

    const CardContent = () => {
        return (
            <Grid xs={12} container spacing={0.5} sx={{ height: 1, p: 0.5 }}>
                {!!avatarIcon &&
                    <Grid xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: avatarBgcolor }}>
                            {avatarIcon}
                        </Avatar>
                    </Grid>
                }
                <Grid xs>
                    {!!textPrimary && (
                        tooltipEnabled ? (
                            <Tooltip arrow title={textPrimary} placement="top">
                                <Typography noWrap={!wrapEnabled} variant="body1" sx={{ ...(!!textPrimaryStyle ? textPrimaryStyle : defaultPrimaryStyle) }}>
                                    {textPrimary}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography noWrap={!wrapEnabled} variant="body1" sx={{ ...(!!textPrimaryStyle ? textPrimaryStyle : defaultPrimaryStyle) }}>
                                {textPrimary}
                            </Typography>
                        )
                    )}
                    {!!textSecondary &&
                        <Typography noWrap={!wrapEnabled} variant="body1" sx={{ ...(!!textSecondaryStyle ? textSecondaryStyle : defaultSecondaryStyle) }}>
                            {textSecondary}
                        </Typography>
                    }
                    {!!textTertiary &&
                        <Typography noWrap={!wrapEnabled} variant="body1" sx={{ ...(!!textTertiaryStyle ? textTertiaryStyle : defaultTertiaryStyle) }}>
                            {textTertiary}
                        </Typography>
                    }
                    {!!textQuaternary &&
                        <Typography noWrap={!wrapEnabled} variant="body1" sx={{ ...(!!textQuaternaryStyle ? textQuaternaryStyle : defaultQuaternaryStyle) }}>
                            {textTertiary}
                        </Typography>
                    }
                </Grid>
                {!!actionIcon &&
                    <Grid xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {actionIcon}
                    </Grid>
                }
            </Grid>
        );
    }

    return (
        <Card elevation={0} sx={{ bgcolor: cardBgcolor ?? SubcardBgcolor, borderRadius: MainBorderRadius, height: cardHeight ?? 'auto' }}>
            {!!actionClick ?
                (
                    <CardActionArea onClick={actionClick} sx={{ height: 1, p: 0.5 }}>
                        <CardContent />
                    </CardActionArea>
                ) : (
                    <Box sx={{ height: 1, p: 0.5 }}>
                        <CardContent />
                    </Box>
                )
            }
        </Card >
    );
}
