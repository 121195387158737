import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CardActionArea, ListItem, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardHeader, ListItemBackground, MainBorderRadius } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';

export default function ArticleListCard({ item, articleId, news, fullWidth }) {
    const navigate = useNavigate();
    const { newArticles, newArticlesStatus } = useSelector((state) => state.news);
    const [newArticle, setNewArticle] = useState(false);

    const Redirect = (title, date) => {
        if (news)
            navigate(`/nieuws/${encodeURIComponent(title)}/${date}/${articleId}`)
        else
            navigate(`/informatie/${encodeURIComponent(title)}/${articleId}`)
    };

    useEffect(() => {
        if (news && newArticlesStatus === 'success' && newArticles?.includes(articleId)) {
            setNewArticle(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newArticlesStatus]);

    return (
        <CardActionArea sx={{ borderRadius: MainBorderRadius, maxWidth: fullWidth ? '100%' : { xs: '100%', md: '70%' } }} onClick={() => Redirect(item.title, GetDate(item.date), item.id)}>
            <ListItem sx={{ ...ListItemBackground }}>
                <ListItemText
                    primary={<Typography noWrap sx={{ ...CardHeader, color: news && newArticle ? 'secondary.main' : !news ? 'secondary.main' : 'primary.main', fontWeight: news && newArticle ? 'bold' : !news ? 'bold' : '', display: 'inline-block', maxWidth: '100%' }}>{item.title}</Typography>}
                    secondary={news ? `${GetDate(item.date)} (${GetTime(item.date)})` : ''}
                />
                <ArrowForwardIosIcon color="secondary" />
            </ListItem>
        </CardActionArea>
    );
}
