import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { ApplicationPaths, LogoutActions, QueryParameterNames } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

export default function Logout(props) {
    const [state, setState] = useState({
        message: undefined,
        isReady: false,
        authenticated: false,
    });

    useEffect(() => {
        const action = props.action;

        const logout = async (returnUrl) => {
            const authState = { returnUrl };
            const isAuthenticated = await authService.isAuthenticated();

            if (isAuthenticated) {
                const result = await authService.signOut(authState);

                switch (result.status) {
                    case AuthenticationResultStatus.Redirect:
                        break;
                    case AuthenticationResultStatus.Success:
                        await navigateToReturnUrl(returnUrl);
                        break;
                    case AuthenticationResultStatus.Fail:
                        setState({ message: result.message });
                        break;
                    default:
                        throw new Error('Invalid authentication result status.');
                }
            } else {
                setState({ message: 'You successfully logged out!' });
            }
        };

        const processLogoutCallback = async () => {
            const url = window.location.href;
            const result = await authService.completeSignOut(url);

            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    throw new Error('Should not redirect.');
                case AuthenticationResultStatus.Success:
                    await navigateToReturnUrl(getReturnUrl(result.state));
                    break;
                case AuthenticationResultStatus.Fail:
                    setState({ message: result.message });
                    break;
                default:
                    throw new Error('Invalid authentication result status.');
            }
        };

        const populateAuthenticationState = async () => {
            const authenticated = await authService.isAuthenticated();
            setState({ isReady: true, authenticated });
        };

        const getReturnUrl = (state) => {
            const params = new URLSearchParams(window.location.search);
            const fromQuery = params.get(QueryParameterNames.ReturnUrl);

            if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
                throw new Error(
                    'Invalid return url. The return url needs to have the same origin as the current page.'
                );
            }

            return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationPaths.LoggedOut}`;
        };

        const navigateToReturnUrl = (returnUrl) => {
            return window.location.replace(returnUrl);
        };

        const handleComponentDidMount = async () => {
            switch (action) {
                case LogoutActions.Logout:
                    logout(getReturnUrl());
                    break;
                case LogoutActions.LogoutCallback:
                    await processLogoutCallback();
                    break;
                case LogoutActions.LoggedOut:
                    console.log(" HERE ")
                    setState({ isReady: true, message: 'Je bent succesvol uitgelogd!' });
                    break;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }

            await populateAuthenticationState();
        };

        handleComponentDidMount();
    }, [props.action]);

    const { isReady, message } = state;

    if (!isReady) {
        return <div></div>;
    }

    if (!!message) {
        return (
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'center', mt: 2 }}>
                <Typography variant="h6">{message}</Typography>
            </Box>
        );
    } else {
        const action = props.action;

        switch (action) {
            case LogoutActions.Logout:
                return (
                    <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'center', mt: 2 }}>
                        <Typography variant="h6">Uitloggen...</Typography>
                    </Box>
                );
            case LogoutActions.LogoutCallback:
                return <div></div>;
            case LogoutActions.LoggedOut:
                return (
                    <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'center', mt: 2 }}>
                        <Typography variant="h6">Je bent succesvol uitgelogd!</Typography>
                    </Box>
                )
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }
};